#olfitProfessional {

    .video{
        .video-wrap{
            position: relative;
            cursor: pointer;
            .play{
                position: absolute;
                left: 50%;
                top: 50%;
                transform: translate(-50%, -50%);
            }
        }
    }

    h1 {
        margin: 0;
        font-weight: 900;
    }

    h2 {
        font-weight: 900;
        margin-bottom: 20px;

        &.text-center {
            text-align: center;
        }
    }

    sub{
        display: inline-block;
    }

    h3 {
        color: $color2;
        font-weight: 900;
        margin-top: 5px;
        font-size: 18px;
    }

    h4 {
        font-weight: 900;
        font-size: 20px;
    }

    h6 {
        font-size: 16px;
        color: $color2;
        font-weight: 900;
        @media (min-width: $screen-lg-min) {
            font-size: 20px;
        }
    }

    .intro {
        font-size: 20px;
        margin: 15px 0;
    }

    p {
        margin: 15px 0;
        line-height: 1.7;
        font-size: 14px;
        @media (min-width: $screen-sm-min) {
            font-size: 16px;
        }
        @media (min-width: $screen-md-min) {
            font-size: 18px;
        }
    }

    .companies{
        #companySlider{
            background-color: white;
            border-radius: 10px;
            padding: 45px 0;
            margin-top: 20px;
            overflow: hidden;
            .slick-list{
                margin: 0 -30px;
            }
            .slick-slide{
                margin: 0 30px;
                display: flex;
                height: 100px;
                align-items: center;
                justify-content: center;
                img{
                    display: block;
                    max-height: 100px;
                    width: auto;
                    max-width: 100%;
                }
            }
        }
    }

    .btn {
        margin-bottom: 20px;
    }

    .professional-info{
        img{
            width: 50%;
        }
    }

    #profSlider {
        border-radius: 5px;
        overflow: hidden;

        .top {
            background-color: #fff;
            padding: 30px;

            .menu {
                display: flex;
                justify-content: center;
                gap: 10px;
                padding: 0;
                margin: 0;
                list-style: none;
                flex-wrap: wrap;

                li {
                    a {
                        display: block;
                        border-radius: 50px;
                        border: 1px solid #DCDCDC;
                        background-color: #fff;
                        color: #8E8E8E;
                        font-size: 12px;
                        font-weight: 900;
                        padding: 10px 20px;
                        transition: all .3s;

                        &.active {
                            border: 1px solid $color2;
                            color: #3E3E3E;
                            background-color: #FF630819;
                        }

                        &:hover {
                            border: 1px solid $color2;
                            color: #3E3E3E;
                            background-color: #FF630819;
                        }
                    }
                }
            }

            .content {
                position: relative;
                padding: 0;
                padding-bottom: 40px;
                background-color: white;

                p {
                    font-size: 14px;
                    @media (min-width: $screen-sm-min) {
                        font-size: 16px;
                    }
                    @media (min-width: $screen-md-min) {
                        font-size: 18px;
                    }
                }

                .nav {
                    position: absolute;
                    justify-content: space-between;
                    width: 100%;
                    left: 50%;
                    bottom: 0;
                    transform: translate(-50%, 0%);
                    pointer-events: none;
                    display: flex;

                    .slick-prev, .slick-next {
                        border-radius: 50px;
                        border: 1px solid #DCDCDC;
                        width: 48px;
                        height: 48px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        transition: all .3s;
                        cursor: pointer;
                        pointer-events: all;

                        &:hover {
                            border: 1px solid $color2;
                            background-color: #FF630819;

                            i {
                                color: $color2;
                            }
                        }

                        i {
                            transition: all .3s;
                            font-size: 16px;
                            color: #8E8E8E;
                        }
                    }
                }
            }
        }

        .bottom {
            img {
                width: 100%;
            }
        }
    }

    .info-list {
        list-style: none;
        padding: 0;
        margin: 0 0 20px 0;

        li {
            display: flex;
            align-items: baseline;
            gap: 10px;
            font-size: 14px;
            @media (min-width: $screen-sm-min) {
                font-size: 16px;
            }

            &:before {
                content: '\e902';
                font-family: 'icomoon';
                color: $color2;
                font-size: 16px;
            }
        }
    }

    .prof-img {
        border-radius: 10px;
    }

    .wrapper {
        padding: 60px 0;

        &.pt-0 {
            padding-top: 0;
        }

        .content {
            border: none;
        }

        &.wrapper-gray {
            background: $lightgray;
        }

        p {
            font-size: 14px;
            @media (min-width: $screen-sm-min) {
                font-size: 16px;
            }
            @media (min-width: $screen-md-min) {
                font-size: 18px;
            }

            &.smaller {
                font-size: 14px;
                @media (min-width: $screen-sm-min) {
                    font-size: 16px;
                }
            }
        }

        [class^=icon-] {
            font-size: 90px;
            color: $color2;
        }

        .btn.ghost {
            margin-left: 15px;
        }
    }

    .cta {
        background-color: $color2;
        color: #fff;
        overflow: hidden;

        .row {
            display: flex;
            flex-direction: column-reverse;
        }

        .info{
            padding: 40px 0;
        }

        .img-wrap{
            height: 50%;
            display: flex;
            align-items: flex-end;
            justify-content: center;
            .bg{
                background: transparent radial-gradient(closest-side at 50% 50%, #FFFFFF 0%, #FF6308 100%) 0% 0% no-repeat padding-box;
                opacity: 0.5;
                position: absolute;
                width: 100%;
                height: 100%;
                z-index: 0;
            }
            img{
                width: 50%;
                position: relative;
            }
        }

        h3 {
            color: #fff;
        }

        ul {
            list-style: none;
            padding: 0;
            margin: 0;
            line-height: 28px;

            li {
                a {
                    color: #fff;

                    i {
                        margin-right: 10px;
                    }
                }
            }
        }
    }
}

@media (min-width: $screen-sm-min) {
    #olfitProfessional  {
        .cta {
            .row {
                flex-direction: row;
            }
            .img-wrap{
                height: 100%;
                img{
                    width: 60%;
                }
            }
        }

        .professional-info{
            img{
                width: 100%;
            }
        }
    }
}

@media (min-width: $screen-md-min) {
    #olfitProfessional  {
        h1{
            font-size: 40px;
        }
        h2{
            font-size: 30px;
        }
        h3{
            font-size: 20px;
        }
        p {
            font-size: 18px;

            &.smaller {
                font-size: 16px;
            }
        }
        #profSlider {
            .top {
                .content {
                    padding-bottom: unset;
                    .nav {
                        display: flex;
                        top: 50%;
                        bottom: unset;
                        transform: translate(-50%, -50%);
                    }
                }

                .content {
                    padding: 30px 80px;

                    p {
                        font-size: 18px;
                    }
                }

                .menu {
                    gap: 20px;

                    li {
                        a {
                            font-size: 16px;
                        }
                    }
                }
            }
        }

        .professional-info{
            img{
                width: 100%;
            }
        }

        .cta {
            h3{
                font-size: 30px;
            }
            ul{
                font-size: 16px;
            }
            .row {
                display: flex;
                flex-direction: row;
            }

            .img-wrap{
                img{
                    width: 70%;
                }
            }
        }
    }
}