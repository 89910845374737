#thanks {
    text-align: center;

    .content {
        background: #F5F5F5;
        padding: 30px 0;
        font-size: 16px;

        h1 {
            font-size: 22px;
            margin: 0 0 30px;

            .icon {
                display: inline-block;
                color: $color2;
                margin-right: 5px;
            }
        }

        strong.title {
            font-size: 18px;
        }
    }

    .icons {
        padding: 30px 0;
        font-size: 16px;

        .icon {
            width: 105px;
            margin-bottom: 30px;
        }

        h2,
        button {
            display: block;
        }

        h2 {
            font-size: 22px;
        }
    }
}

@media (min-width: $screen-sm-min) {
    #thanks {
        .content,
        .icons {
            padding: 50px 0;
            font-size: 18px;
        }
        .icons {
            h2,
            button {
                display: inline-block;
            }

            h2 {
                font-size: 26px;
                margin: 50px 30px 50px 0;
            }
        }
    }
}