#decisionAid {
    p {
        font-size: 18px;
        margin-bottom: 30px;
    }

    .step-content {
        display: flex;
        justify-content: center;

        h1 {
            text-align: center;
            font-size: 24px;
            font-weight: 700;
            margin-bottom: 30px;
        }

        #stepContainer {
            background: #FFF;
            padding: 30px;
            text-align: center;
            border-radius: 5px;
            display: flex;
            flex-direction: column;
            align-items: center;

            h2 {
                font-size: 20px;
                font-weight: 700;
            }

            #stepsWrapper {
                display: flex;
                justify-content: center;
                margin-bottom: 30px;

                > div {
                    position: relative;
                    display: inline-flex;

                    .step {
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        width: 29px;
                        height: 29px;
                        border-radius: 50%;
                        background: #FFF;
                        border: 1px solid #FF63084D;
                        font-weight: 700;

                        &.active {
                            background: #FF6308;
                            border: 1px solid #FF6308;
                            color: #FFF;
                        }
                    }

                    .separator {
                        content: '';
                        display: block;
                        height: 17px;
                        width: 20px;
                        border-bottom: 4px dotted #FF6308;
                        margin: 0 4px;
                    }
                }
            }

            form {
                margin-top: 30px;
                display: flex;
                justify-content: center;

                .button-wrapper {
                    &:not(:last-child) {
                        margin-right: 20px;
                    }
                }

                button {
                    background: #FF6308;
                    color: #FFF;
                    border: none;
                    border-radius: 5px;
                    font-size: 20px;
                    height: 60px;
                    line-height: 60px;
                    padding: 0 30px;
                    transition: all 0.3s;

                    + input[type=radio] {
                        display: none;
                    }

                    &:hover {
                        background: darken(#FF6308, 10);
                        color: #FFF;
                    }
                }
            }

            ul {
                list-style: none;
                display: flex;
                flex-direction: column;
                justify-content: center;
                margin: 0 0 15px 0;
                padding: 0;

                li {
                    display: flex;
                    align-items: center;
                    font-size: 18px;
                    margin-right: 15px;

                    &:last-child {
                        margin-right: 0;
                    }

                    i {
                        width: 20px;
                        height: 20px;
                        border-radius: 50%;
                        display: inline-flex;
                        align-items: center;
                        justify-content: center;
                        margin-right: 5px;
                        background: #FF6308;
                        color: #FFF;
                        font-size: 12px;
                    }
                }
            }

            .reset {
                display: inline-block;
                font-size: 18px;
                text-decoration: underline;
            }
        }

        .alert {
            margin-top: 30px;
            text-align: center;

            p {
                margin-bottom: 0;
            }
        }
    }

    .products-container {
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        width: 100%;

        .product-wrapper {
            margin-bottom: 30px;

            .product {
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                background: #FFF;
                height: 100%;
                padding: 30px;
                border: 2px solid #F6F2F0;
                transition: all 0.3s;

                &:hover {
                    border: 2px solid #E0E0E0;
                }

                .product-image {
                    img {
                        display: block;
                        width: 100%;
                    }
                }

                .product-info {
                    h3 {
                        font-weight: bold;
                        font-size: 18px;
                        color: #000;
                    }

                    .usps {
                        margin: 0 0 15px 0;
                        padding: 0;
                        list-style: none;
                        font-size: 14px;

                        li {
                            color: #000;

                            i {
                                margin-right: 5px;
                                color: #FF6308;
                            }
                        }
                    }

                    .product-price {
                        font-weight: bold;
                        font-size: 18px;
                        color: #FF6308;
                        margin-bottom: 0;

                        small {
                            display: none;
                        }
                    }
                }
            }
        }
    }

    #results {
        margin-top: 30px;

        h2 {
            font-size: 30px;
            font-weight: 700;
            margin-bottom: 30px;
            text-align: center;
        }
    }
}

@media (min-width: $screen-sm-min) {
    #decisionAid {
        p {
            font-size: 20px;
        }

        .step-content {

            h1 {
                font-size: 36px;
            }

            #stepContainer {
                h2 {
                    font-size: 26px;
                }

                #stepsWrapper {
                    > div {
                        .separator {
                            width: 40px;
                        }
                    }
                }

                ul {
                    flex-direction: row;
                }
            }
        }
    }
}

@media (min-width: $screen-md-min) {
    #decisionAid {
        p {
            font-size: 20px;
        }

        .step-content {

            h1 {
                font-size: 40px;
            }

            #stepContainer {
                h2 {
                    font-size: 30px;
                }
            }
        }
    }
}
