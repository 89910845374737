.clerk-holder {
    ul {
        padding-left: 0;

        .gerelateerdproduct {
            padding: 15px;
            border: 2px solid $color1;
            border-radius: 5px;
            margin-right: 30px;
            width: 100%;
            text-align: center;
    
            h3 {
                margin-bottom: 0;
    
                a {
                    color: #3E3E3E;
                    font-size: 18px;
                }
            }
        }
    }
}

@media (min-width: $screen-sm-min) {
    .clerk-holder {
        ul {
            .gerelateerdproduct {
                width: calc(33.33% - 33px);
            }
        }
    }
}

@media (min-width: $screen-md-min) {
    .clerk-holder {
        ul {
            .gerelateerdproduct {
                width: calc(25% - 33px);

                .gerelateerdproduct-photo {
                    a {
                        img {
                            max-width: 160px !important;
                        }
                    }
                }
            }
        }
    }
}

@media (min-width: $screen-lg-min) {
    .clerk-holder {
        ul {
            .gerelateerdproduct {
                width: calc(20% - 32px);
            }
        }
	}
}