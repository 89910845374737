.btn {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    background: $color2;
    color: $white;
    border: none;
    font-weight: bold;
    height: 48px;
    font-size: 14px;
    padding: 0 25px;
    border-radius: 100px;
    transition: .2s;
    text-align: center;

    &.no-height {
        height: auto;
    }

    &.ghost {
        color: $color2;
        background: transparent;
        border: 2px solid $color2;
        transform: translateY(2px);

        &:hover {
            color: $color2;
            transform: translateY(1px);
        }
    }

    &.more-padding{
        padding: 0 60px;
    }

    &:hover {
        transform: translateY(-1px);
        color: $white;
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    }

    &.btn-white {
        background: $white;
        color: $color2;
        box-shadow: 0 3px 6px #D8B8A533;

        &:hover {
            background: $color2;
            color: $color1;
        }
    }

    &.btn-light{
        background: #FF630826;
        color: $color2;

        &:hover {
            background: $color2;
            color: white;
        }
    }

    &.btn-primary {
        background: $color2;

        &:hover {
            background: darken($color2, 5%)!important;
        }
    }

    &.btn-100 {
        width: 100%;
    }

    i {
        font-size: 18px;
    }

    &:not(.btn-icon-only) {
        i {
            float: left;
            line-height: 40px;
            width: 40px;
            text-align: center;
            margin-left: -15px;
        }
    }

    &.grey {
        background: #8E8E8E;
    }

    &.dark{
        background: #3E3E3E;
    }
}

@media (min-width: $screen-sm-min) {
    .btn{
        font-size: 16px;
    }
}

@media (min-width: $screen-md-min) {
    .btn {
        height: 60px;
        font-size: 18px;
    }
}