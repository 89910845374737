#checkout {
    padding: 30px 0;

    .steps {
        padding: 20px 0 10px;
        margin-bottom: 20px;
        border-bottom: 1px solid $grey;

        .step {
            display: inline-block;
            margin-right: 25px;
            text-transform: uppercase;
            font-weight: 700;
            color: #a4b3bb;

            .number {
                height: 25px;
                width: 25px;
                display: inline-block;
                border-radius: 50%;
                background-color: $grey;
                color: #a4b3bb;
                text-align: center;
                margin-right: 5px;
            }

            &.active {
                .number {
                    background-color: $color1;
                    color: $white;
                }

                color: $black;
            }
        }
    }

    .row.button {
        margin-top: 15px;
    }

    form {
        label {
            font-weight: 400;
        }

        .form-group {
            &.has-error {
                input,
                select,
                textarea {
                    //background-color: #f9f2f4;
                    border-color: #a94442;
                }

                .help-block {
                    font-size: 12px;
                }
            }
        }

        .address {
            background: #f6f2f0;
            border-radius: 5px;
            padding: 10px 15px;
        }

        .radiobuttons {
            margin-bottom: 15px;

            label {
                margin-right: 15px;
            }
        }

        input[type="radio"] {
            position: relative;
            margin-right: 10px;
            appearance: none;
            height: 16px;
            width: 16px;
            background: #fff;
            border: 2px solid #DCDCDC;
            border-radius: 50%;
            transition: all 300ms ease;
            outline: none;
            transform: translateY(3px);

            &:before {
                content: ' ';
                position: absolute;
                height: 8px;
                width: 8px;
                border-radius: 50%;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                background: #fff;
            }

            &:checked {
                background: $color2;
                border-color: $color2;
            }
        }

        .gender {
            label {
                margin-right: 25px;
            }
        }

        .shipping-address {
            display: none;
        }
    }

    h2 {
        font-size: 18px;
        font-weight: bold;
        margin: 15px 0 20px;
    }

    .loader {
        padding: 50px 0;
    }

    .method {
        display: block;
        background: #fff;
        border-radius: 5px;
        border: 2px solid #f6f2f0;
        padding: 15px;
        margin-bottom: 10px;
        margin-right: 0 !important;

        input[type="checkbox"] {
            position: relative;
            margin: 0;
        }

        .image,
        .description {
            margin-left: 15px;
        }
    }

    #discount {
        margin-bottom: 15px;
        background: #f6f2f0;
        border: 2px solid $color1;
        border-radius: 5px;
        padding: 15px;

        h2 {
            font-size: 18px;
            margin-bottom: 15px;
            margin-top: 0;
        }

        .form-group {
            overflow: hidden;
            margin-bottom: 0;

            input {
                width: 70%;
                float: left;
                box-shadow: none;
                background: white;
                border-radius: 5px 0 0 5px;
                line-height: 48px;
                height: 40px;
                padding-left: 15px;
                border: none;
            }

            button {
                width: 30%;
                float: left;
                background: $color2;
                color: #fff;
                border: none;
                line-height: 48px;
                height: 40px;
                border-radius: 0 5px 5px 0;
            }
        }

        .discount-coupon {
            .code {
                font-size: 18px;

                b {
                    font-family: Consolas, "Liberation Mono", Courier, monospace;
                }
            }

            .remove {
                font-size: 28px;
                font-weight: 700;
                line-height: 26px;
                color: #000;
            }
        }
    }

    #order {
        background: #f6f2f0;
        padding: 15px;
        border: 2px solid #F6F2F0;
        border-radius: 5px;

        h2 {
            margin-top: 0;
            text-align: center;
        }

        table {
            tr {
                border-bottom: 1px solid $color1;

                td {
                    text-align: center;

                    padding: 20px 0;

                    &.quantity,
                    &.subtotal {
                        border-bottom: none;
                        width: 50%;
                        float: left;
                        text-align: center !important;
                    }
                }

                &:last-child {
                    border-bottom: 0;
                }
            }
        }

        .summary {
            border-top: 1px solid #DCDCDC;
        }

        .tax,
        .subtotal,
        .discount {
            > div {
                margin-bottom: 15px;
            }
        }

        .shipping {
            p {
                font-size: 14px;
            }
        }

        .handling {
            &.hidden {
                display: none;
            }

            > div {
                margin-top: 15px;
            }
        }

        .checkout {
            border-top: 1px solid #DCDCDC;
            padding-bottom: 0 !important;

            .total {
                margin-bottom: 0;

                .col-xs-4 {
                    color: $color2;
                }
            }
        }
    }

    #segment {
        margin-top: 15px;
        background: #E6E6E6;
        padding: 15px;
        border-radius: 5px;

        p:last-child {
            margin-bottom: 0;
        }
    }

    #service {
        margin-top: 15px;
        padding: 15px;
        background: rgba($color2, 0.1);
        border-radius: 5px;

        img {
            width: 50px;
        }

        h3 {
            color: $color2;
            font-size: 18px;
            line-height: 22px;
            margin: 0 0 2px 0;
        }

        p {
            color: #8E8E8E;
            font-size: 15px;
            line-height: 18px;
            margin: 0;
        }
    }

    .checkbox-wrapper {
        label {
            position: relative;
            font-size: 16px;
            padding-left: 30px;
            width: 100% !important;
            margin: 0;

            input[type="checkbox"] {
                position: absolute;
                left: 0;
                appearance: none;
                height: 18px;
                width: 18px;
                border: 2px solid #DCDCDC;
                border-radius: 2px;
                background: #fff;
                top: 3px;
                margin: 0 5px 0 0;
                transition: all 300ms ease;
                outline: none;

                &:before {
                    content: ' ';
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                    border-radius: 1px;
                    height: 9px;
                    width: 9px;
                    background: #fff;
                    opacity: 0;
                    transition: all 300ms ease;
                }

                &:checked {
                    border-color: $color2;
                    background: $color2;

                    &:before {
                        opacity: 1;
                    }
                }
            }
        }
    }
}

@media (min-width: $screen-sm-min) {
    #checkout {
        .opmerking-wrap {
            .form-group {
                > div {
                    float: unset;
                    width: 100%;
                }
            }
        }

        .form-group {
            overflow: hidden;

            label,
            > div {
                float: left;
            }

            label {
                width: 35%;
            }

            > div {
                width: 65%;
            }
        }

        #order {
            h2 {
                text-align: left;
                margin-bottom: 0;
            }

            table {
                tr {
                    td {
                        text-align: left;
                        font-size: 12px;
                        line-height: 1;

                        &.product {
                            padding: 20px 0 20px 10px;
                        }

                        a {
                            font-size: 12px;
                        }

                        &.image {
                            img {
                                width: 66px;
                                border-radius: 5px;
                            }
                        }

                        &.quantity,
                        &.subtotal {
                            border-bottom: none;
                            width: initial;
                            float: none;
                            text-align: right !important;
                        }
                    }

                    &:last-child {
                        border-bottom: 0;
                    }
                }
            }
        }
    }
}

@media (min-width: $screen-md-min) {
    #checkout {
        .scroller {
            position: sticky;
            top: 30px;
        }
    }
}