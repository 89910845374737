#brands {
    .panel {
        position: relative;
        margin-bottom: 30px;
        background: #fff;
        box-shadow: 0 0 25px rgba(0, 0, 0, 0.1);

        .panel-body {
            text-align: center;
            padding: 15px;
            transition: all 0.3s;

            img {
                padding-bottom: 25px;
            }

            h2 {
                margin: 0;
                text-transform: uppercase;
                font-weight: 700;
                font-size: 16px;
            }
        }

        .overlay {
            position: absolute;
            background: rgba(255, 255, 255, 0.5);
            top: 0;
            left: 0;
            height: 100%;
            width: 100%;
            opacity: 0;
            z-index: 1;
            transition: all 300ms ease;

            .btn {
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
            }

            &:hover {
                opacity: 1;
            }
        }
    }
}