#article {
    margin: 50px 0;

    article {
        .date {
            display: block;
            color: #5F5F5F;
            margin: 30px 0;
        }

        h1 {
            margin: 0 0 25px;
        }

        h2 {
            font-size: 16px;
        }

        h3 {
            font-weight: bold;
            font-size: 14px;
        }

        .intro {
            font-size: 16px;
            margin: 15px 0;
        }
    }

    .more-articles {
        h2 {
            margin: 0 0 15px;
            text-transform: uppercase;
            font-weight: bold;
            font-size: 16px;
            color: $color2;
        }

        p {
            margin-bottom: 10px;
        }

        a {
            display: block;
            border-top: 1px solid #EFF1F3;

            &:last-child {
                border-bottom: 1px solid #EFF1F3;
            }

            > article {
                padding: 15px 0;
                color: $black;
                transition: all 300ms ease;
                overflow: hidden;

                img {
                    float: left;
                    border: 5px solid $white;
                    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
                    margin-right: 15px;
                    padding: 0 !important;
                }

                &:hover,
                &.active {
                    color: $color2;
                }
            }
        }
    }

    .share {
        border-bottom: 1px solid #D6DDE0;

        ul {
            margin: 0;
            padding: 15px 0;

            li {
                display: inline-block;
                padding: 0 5px;
                list-style: none;

                &:first-child {
                    padding-left: 0;
                }

                a {
                    color: $black;
                    text-decoration: none;
                    transition: all 300ms ease;
                    font-size: 18px;

                    &:hover {
                        color: $color1;
                    }
                }
            }
        }
    }
}

@media (min-width: $screen-md-min) {
    #article {
        article {
            .img-responsive{
                max-width: 50%;
                float: right;
                padding: 0 0 15px 15px;
            }
            img{
                max-width: 100%;
            }
        }
    }
}

@media (min-width: $screen-lg-min) {
    #article {
        article {
            img{
                max-width: 50%;
            }
        }
    }
}