#cookiebox {
    position: fixed;
    padding: 30px;
    border-bottom: 2px solid $color1;
    width: calc(100% - 60px);
    transform: translateX(-50%);
    left: 50%;
    max-width: 500px;
    bottom: -230px;
    background: $white;
    z-index: 10;
    text-align: center;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
    transition: all 500ms ease;
}