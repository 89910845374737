#experiences {

    .experiences {
        .experience {
            position: relative;
            margin-top: 30px;
            background: #f6f2f0;
            overflow: hidden;
            border-radius: 10px;

            img {
                float: left;
                width: 25%;
                margin-right: 30px;
            }

            .experience_content {
                float: left;
                width: calc(75% - 30px);
                padding: 30px 30px 0 0;

                h3 {
                    font-size: 22px;
                    color: #000;
                    font-weight: bold;
                    margin-top: 0;

                    span {
                        float: right;
                        display: inline-block;
                        padding: 2px 10px;
                        margin-top: -5px;
                        font-size: 14px;
                        font-weight: normal;
                        background: $color2;
                        color: white;
                        border-radius: 10px 10px 10px 0;
                    }
                }
            }
        }
    }
}