.filter {
    display: none;

    h2 {
        font-size: 18px;
        margin-bottom: 0;
    }

    .filter_wrapper {
        background: #F6F2F0;
        padding: 15px;
        border-top: none;
        border-radius: 5px;
        overflow: hidden;

        .remove {
            font-size: 16px;
            color: #8E8E8E;
            font-weight: bold;

            .icon {
                margin-right: 15px;
            }
        }

        .part {
            margin: 15px 0 0;
            padding: 15px 0 0;
            border-top: 1px solid rgba(0, 0, 0, 0.1);

            .title {
                color: #3E3E3E;
                font-size: 16px;
                font-weight: bold;
                margin-bottom: 10px;

                .icon {
                    float: right;
                    color: #8E8E8E;

                    &.info {
                        float: none;
                        vertical-align: text-bottom;
                        margin-left: 5px;
                    }
                }
            }

            .content {
                overflow: hidden;
                transition: all 300ms ease;

                label {
                    display: block;
                    font-weight: normal;

                    input {
                        position: relative;
                        appearance: none;
                        border: 1px solid rgba(0, 0, 0, 0.1);;
                        border-radius: 3px;
                        height: 13px;
                        width: 13px;
                        display: inline-block;
                        margin-right: 5px;
                        background: #fff;
                        outline: 0;
                        transform: translateY(1px);

                        &:before {
                            content: '\e91b';
                            font-family: 'Icomoon', sans-serif;
                            position: absolute;
                            font-size: 9px;
                            top: 50%;
                            left: 50%;
                            color: $color2;
                            transform: translate(-50%, -50%);
                            opacity: 0;
                            transition: all 300ms ease;
                        }

                        &:checked {
                            &:before {
                                opacity: 1;
                            }

                            border: 1px solid rgba($color2, 0.5);
                            font-weight: bold;
                        }
                    }
                }
            }

            &:last-child {
                padding-bottom: 0;
                margin-bottom: 0;

                &.more {
                    margin-bottom: 30px;
                }
            }

            &.more {
                position: relative;

                .show-more {
                    display: none;
                    position: absolute;
                    left: 0;
                    bottom: -30px;
                    font-weight: bold;

                    .icon {
                        margin-left: 5px;
                        font-size: 12px;
                    }

                    &:focus {
                        color: $color2;
                    }
                }

                &.active {
                    margin-bottom: 45px;

                    .show-more {
                        display: block;
                    }
                }
            }
        }
    }

    .service {
        display: block;
        margin-top: 15px;
        padding: 15px;
        background: rgba($color2, 0.1);
        border-radius: 5px;

        img {
            width: 50px;
        }

        h3 {
            color: $color2;
            font-size: 18px;
            line-height: 22px;
            margin: 0 0 2px 0;
        }

        p {
            color: #8E8E8E;
            font-size: 15px;
            line-height: 18px;
            margin: 0;
        }
    }
}

@media (min-width: $screen-sm-min) {
    .filter {
        display: block;
        border-top-right-radius: 0;
    }
}