.topmessage {
    background: $color2;
    color: #FFF;
    text-align: center;
    padding: 7px 0;

    p {
        margin-bottom: 0;
    }

    a {
        color: #fff;
        text-decoration: none;
    }
}

header {
    position: relative;
    background: $white;

    .search-wrap {
        z-index: 1;
        background-color: white;
        border-radius: 100px;
        height: 42px;
        display: flex;
        align-items: center;
        padding: 0 20px;

        button, input {
            border: none;
            background: none;
        }

        input {
            font-size: 14px;

            &::placeholder {
                color: $text;
            }
        }

        button {
            i {
                color: $color2;
                font-size: 18px;
            }
        }
    }

    .top-header {
        background-color: $lightgray;

        .top-header-wrap {
            height: 40px;

            ul {
                li {
                    a {
                        color: $text;
                        transition: all .3s;

                        &:hover {
                            color: $color2;
                        }
                    }
                }
            }
        }
    }

    .logo {
        height: 40px;
        margin: 15px 0;
    }

    .middle-header {
        display: flex;
        align-items: center;
        justify-content: space-between;

        .mob-menu-wrap {
            display: flex;
            align-items: center;
            gap: 25px;

            .menu-btn {
                background-color: $color2;
                color: white;
                width: 48px;
                height: 48px;
                display: flex;
                align-items: center;
                justify-content: center;
                border-radius: 100%;
                font-size: 18px;
            }
        }
    }

    .user-btn {
        display: flex;
        align-items: center;
        gap: 10px;
        color: $text;
        background-color: white;
        border-radius: 100px;
        font-size: 18px;
        transition: all .2s;
    }

    //.searchtrigger{
    //    height: 40px;
    //    width: 40px;
    //    border-radius: 100px;
    //    background-color: white;
    //    color: $text;
    //    font-size: 18px;
    //    display: flex;
    //    align-items: center;
    //    position: absolute;
    //    justify-content: center;
    //    z-index: 1;
    //    top: 10px;
    //    right: 188px;
    //}

    .user {
        display: none;
        position: absolute;
        z-index: 9999999;
        background: white;
        width: 100%;
        left: 0;
        top: 100%;
        transform: translateY(0);

        a {
            transition: all .3s;
        }

        .top {
            padding: 10px 20px;
        }

        .middle {
            padding: 10px 20px;

            a {
                flex-grow: 1;
            }

            button {
                margin: 10px 0;
            }

            span {
                display: block;
            }
        }

        .bottom {

        }
    }

    .cart-popup {
        display: none;
        position: fixed;
        z-index: 9999999;
        background: #fff;
        width: 100%;
        padding: 30px;
        left: 0;
        top: 0;
        transform: translateY(0);
        box-shadow: 0 2px 20px 0 rgba(0, 0, 0, 0.09);

        .old{
            text-decoration: line-through;
        }

        .top {
            padding: 10px 15px;
            font-size: 18px;
            color: #2C1B12;
            border-bottom: 1px solid #F6F2F0;

            i {
                font-size: 12px;
                background-color: $color2;
                color: white;
                border-radius: 100%;
                padding: 2px;
            }
        }

        .middle {
            padding: 15px;

            h4 {
                font-weight: bold;
                color: $text;
            }

            .products {
                margin-bottom: 15px;

                .product {
                    margin: unset;

                    .left {
                        img {
                            width: 100px;
                            height: 100px;
                            object-fit: contain;
                        }
                    }
                }
            }

            .subtotal {
                border-radius: 5px;
                background-color: #F6F2F0;
                padding: 10px;

                p {
                    margin-bottom: 0;
                }
            }
        }

        .bottom {
            padding: 15px;
            background-color: white;

            a {
                flex-grow: 1;
            }
        }
    }

    .cart-btn {
        position: relative;
        font-size: 18px;
        background-color: white;
        color: $text;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        border-radius: 100px;
        transition: all .2s;

        .amount {

            span {
                position: absolute;
                background: $text;
                color: $white;
                font-size: 12px;
                align-items: center;
                justify-content: center;
                height: 25px;
                width: 25px;
                text-align: center;
                border-radius: 50%;
                font-family: 'Lato', sans-serif;
            }
        }
    }

    .search-btn {
        right: 110px;
        top: 26px;
    }

    .fav-btn {
        right: 140px;
        top: 25px;
        font-size: 17px;
    }

    nav {
        position: relative;
        background: $lightgray;
        overflow: hidden;
        display: none;

        ul {
            padding: 0;
            margin: 0;
            //margin: 0 -15px;

            &.first {
                > li {
                    border-bottom: 1px solid #E6E0DE;
                }
            }

            li {
                display: block;
                list-style: none;
                transition: all 300ms ease;
                //height: 46px;
                font-size: 18px;
                overflow: hidden;
                //transition-delay: 600ms;

                //> ul{
                //    li{
                //        a{
                //            padding: 0;
                //            &:first-of-type{
                //                padding: 5px 0;
                //            }
                //        }
                //    }
                //}

                &.open {
                    > ul {
                        max-height: 900px;
                        opacity: 1;
                        padding-top: 10px;

                        > li {
                            a {
                                padding: 5px 0;
                            }
                        }
                    }
                }


                ul {
                    position: relative;
                    top: -10px;
                    max-height: 0;
                    transition: all 300ms ease;
                    padding-left: 10px;
                    opacity: 0;

                    li {
                        //height: 36px;
                        a {
                            color: rgba(44, 27, 18, 0.7);
                        }

                        ul {
                            li {
                                height: 36px;
                            }
                        }
                    }
                }

                a {
                    position: relative;
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    padding: 10px 0;
                    color: $gray-dark;
                    //height: 46px;
                    transition: all 300ms ease;

                    span {
                        display: inline-block;
                        position: relative;
                        left: 0;
                        transition: left 300ms ease 300ms, transform 300ms ease 300ms;
                    }

                    i {
                        float: right;
                        transition: all 300ms ease;
                    }

                    &:before {
                        content: '\e90c';
                        position: absolute;
                        right: 15px;
                        top: 50%;
                        transform: translateY(-50%);
                        font-family: 'icomoon', sans-serif;
                        color: $black;
                        opacity: 0;
                        transition: all 300ms ease;
                        transition-delay: 0ms;
                    }

                    &:hover,
                    &:focus {
                        text-decoration: none;
                    }

                    &:hover {
                        color: #000;
                    }

                }

                &.open {
                    > a {
                        //border-bottom: 1px solid $color2;
                        //color: $color2;
                        //transition-delay: 600ms;
                        overflow: hidden;

                        //span {
                        //    left: 50%;
                        //    transform: translateX(-50%);
                        //    transition-delay: 0ms;
                        //}

                        //&:before,
                        //&:after {
                        //    opacity: 1;
                        //    transition-delay: 300ms;
                        //}

                        i {
                            transform: rotate(180deg);
                        }

                        &.hide {
                            padding: 0 15px;
                            height: 0;
                            border: none;
                        }
                    }
                }

                > ul {
                    margin: 0;
                }

            }

            li:last-child a {
                border-bottom: none;
            }

            &.sub-open {
                > li {
                    transition-delay: 300ms;

                    //&:not(.open) {
                    //    height: 0;
                    //}
                }
            }
        }

        //.container .first > li:last-child > a {
        //    color: $color2;
        //    font-weight: bold;
        //}
    }

    //form#searchform {
    //    position: absolute;
    //    right: 15px;
    //    top: 85px;
    //    width: calc(100% - 147px);
    //    z-index: 2;
    //
    //    .form-group {
    //        margin-bottom: 0;
    //
    //        .form-control {
    //            height: auto;
    //            width: 100%;
    //            padding: 0 10px;
    //            font-size: 14px;
    //            border: none;
    //            border-radius: 100px;
    //            line-height: 40px;
    //        }
    //    }
    //
    //    button {
    //        position: absolute;
    //        right: 5px;
    //        top: 3px;
    //        background: none;
    //        border: none;
    //        line-height: 32px;
    //        color: $color2;
    //        font-size: 16px;
    //    }
    //}

    .clerk-live-search-container {
        min-width: auto !important;

        .clerk-item {
            width: 100%;
            overflow: hidden;
            padding-bottom: 15px;
            border-bottom: 1px solid #EEE;
            margin-bottom: 15px;
            display: flex;
            align-items: center;

            &:last-of-type {
                padding-bottom: 0;
                border-bottom: none;
                margin-bottom: 0;
            }

            img {
                width: 60px;
                height: 60px;
                margin-right: 15px;
                flex-shrink: 0;
            }

            a {
                display: block;
            }
        }
    }

    ul.register {
        list-style: none;
        margin: 0 0 15px 0;
        padding: 0;

        li {
            position: relative;
            display: inline-block;
            font-size: 13px;

            &:first-child {
                padding-right: 12px;
                margin-right: 8px;

                &:after {
                    content: ' ';
                    position: absolute;
                    right: 0;
                    top: 0;
                    width: 1px;
                    height: 20px;
                    background: #EEE;
                }
            }
        }
    }

    ul.account {
        padding: 0;
        margin: 0 -15px;

        li {
            display: block;
            list-style: none;
            transition: all 300ms ease;
            height: 46px;
            overflow: hidden;
            transition-delay: 600ms;

            a {
                position: relative;
                display: block;
                padding: 10px 15px;
                border-bottom: 1px solid #EEE;
                color: $color2;
                height: 46px;
                transition: all 300ms ease;

                span {
                    display: inline-block;
                    position: relative;
                    left: 0;
                    transition: left 300ms ease 300ms, transform 300ms ease 300ms;
                }

                &:hover,
                &:focus {
                    text-decoration: none;
                }

                &:hover {
                    color: #000;
                }

            }
        }

        li:last-child a {
            border-bottom: none;
        }
    }

    .bottom {
        position: relative;
        background: #F6F2F0;
        padding: 15px;

        .menu-btn {
            position: relative;
            display: inline-block;
            height: 40px;
            line-height: 40px;
            padding: 0 20px 0 45px;
            background: $color2;
            border-radius: 5px;
            color: #fff;
            cursor: pointer;
            transition: all 300ms ease;

            .icon {
                position: absolute;
                left: 15px;
                top: 50%;
                transform: translateY(-50%);
            }

            &:hover {
                background: darken($color2, 5%);
            }
        }
    }
}

@media (min-width: $screen-sm-min) {
    header {

        nav{
            .first{
                >li{
                    ul{
                        li{
                            i{
                                &.icon-caret-down{
                                    &:before{
                                        content: '\e90a';
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }

        .cart-popup {
            position: absolute;
            width: 100%;
            top: 100%;
            box-shadow: unset;
        }

        .cart {
            width: initial;
            left: initial;
            height: initial;
            right: 0;
            box-shadow: 0 0 2px rgba(0, 0, 0, 0.1);

            .products {
                max-height: 425px;
            }

            .total-row {
                .btn {
                    &:first-child {
                        margin-right: 10px;
                    }

                    .icon {
                        margin-right: 10px;
                    }

                    span {
                        display: inline-block;
                    }
                }
            }
        }

        //form#searchform {
        //    position: absolute;
        //    left: 160px;
        //    right: auto;
        //    top: 15px;
        //    z-index: 2;
        //    width: 280px;
        //
        //    .form-group {
        //        .form-control {
        //            border: 2px solid #F6F2F0;
        //        }
        //    }
        //}

        .feedbackcompany {
            //max-width: 400px;
            //margin: 0 auto;
            //position: absolute;
            //left: 600px;
            //top: 50%;
            //transform: translateY(-50%);

            .top {
                padding: 5px 10px 5px;
                background: #f5f5f5;
                border-radius: 5px;
                text-align: center;
                display: flex;
                justify-content: space-between;
                align-items: center;
                gap: 7px;

                .feedback_logo {
                    img {
                        width: 20px;
                        height: 20px;
                    }
                }

                .stars {
                    display: inline-block;
                    position: relative;
                    font-size: 8px;
                    vertical-align: middle;
                    color: #fff;

                    .icon {
                        display: inline-block;
                        width: 15px;
                        height: 15px;
                        border-radius: 50%;
                        text-align: center;
                        line-height: 15px;
                    }

                    .percent {
                        position: relative;
                        z-index: 1;
                        white-space: nowrap;
                        overflow: hidden;

                        .icon {
                            background: $color2;
                        }
                    }

                    .background {
                        position: absolute;
                        left: 0;
                        top: 0;
                        z-index: 0;

                        .icon {
                            color: #CCC;
                            background: #fff;
                        }
                    }
                }

                .score {
                    display: inline-block;
                    color: #333333;
                    font-size: 16px;
                    vertical-align: bottom;
                    line-height: 19px;
                    font-weight: bold;
                }

                .amount {
                    color: #333333;
                    font-size: 16px;
                    vertical-align: bottom;
                    line-height: 19px;
                }
            }

            .logo {
                text-align: center;
                padding: 5px 15px;
                background: #F6F2F0;
                margin: 0;
                height: unset;

                img {
                    width: 140px;
                }
            }
        }

        .thuiswinkel {
            position: absolute;
            right: 470px;
            top: 15px;

            img {
                height: 40px;
            }
        }

        .user {
            position: absolute;
            right: 95px;
            left: unset;
            width: 450px;
            z-index: 2;
            background: #FFF;
            border-top: none;
            padding: 0;
            border-radius: 10px 0 10px 10px;
            transform: translateY(10px);
            overflow: hidden;

            .top {
                padding: 10px 20px;
                border-bottom: 1px solid #F6F2F0;

                h2 {
                    font-size: 18px;
                }
            }

            .middle {
                padding: 10px 20px;

                span {
                    display: block;
                    font-size: 16px;
                }
            }

            .bottom {
                border-top: 1px solid #F6F2F0;
                background-color: white;

                ul {
                    font-size: 16px;
                }
            }

            ul {
                margin-bottom: 0;
            }
        }

        .cart-popup {
            right: 15px;
            left: unset;
            width: 450px;
            z-index: 2;
            background: #FFF;
            border-top: none;
            padding: 0;
            border-radius: 10px 0 10px 10px;
            transform: translateY(10px);
            overflow: hidden;
        }
    }
}

@media (min-width: $screen-md-min) {
    header {
        &.fixed {
            position: fixed;
            width: 100%;
            z-index: 11;

            .top-header {
                display: none !important;
            }

            nav {
                position: relative;

                &.overlay-effect {
                    &:before {
                        content: '';
                        position: absolute;
                        width: 100%;
                        height: 100%;
                        left: 0;
                        top: 0;
                        z-index: 10;
                        opacity: 1;
                        background-color: rgba(44, 27, 18, 0.5);
                    }
                }
            }
        }

        + .overlay {
            position: fixed;
            width: 100%;
            height: 100%;
            top: 0;
            background-color: rgba(44, 27, 18, 0.5);
            z-index: 10;
            opacity: 0;
            pointer-events: none;

            &.active {
                opacity: 1;
            }
        }

        nav {
            ul {
                > li {
                    &:not(:last-child) {
                        border-bottom: unset;
                    }
                }

                &.first {
                    > li {
                        border-bottom: unset;
                    }
                }

                li {
                    ul {
                        max-height: unset;

                        &.right {
                            right: 0 !important;
                            left: unset !important;
                        }
                    }
                }
            }
        }

        .top-header {
            z-index: 11;
            position: relative;

            .top-header-wrap {
                ul {
                    li {
                        a {
                            font-size: 16px;
                        }
                    }
                }
            }
        }

        .bg-white {
            z-index: 11;
            position: relative;
        }

        .search-wrap {
            height: 60px;
            width: 330px;
            top: 20px;
            left: 250px;
            position: absolute;
            border: 2px solid #D8B8A533;
            transition: all .3s;

            &.active {
                border: 2px solid $color2;
            }

            input {
                font-size: 18px;
            }
        }

        .user-btn {
            position: absolute;
            top: 20px;
            right: 95px;
            padding: 0 30px;
            height: 60px;
            font-size: 18px;
            background-color: $color2;
            color: white;

            &:hover {
                background-color: darken($color2, 5%);
            }
        }

        .cart-btn {
            position: absolute;
            top: 12px;
            right: 15px;
            font-size: 20px;
            height: 60px;
            width: 60px;
            background-color: $color2;
            color: white;

            &:hover {
                background-color: darken($color2, 5%);
            }

            .amount {

                span {
                    position: absolute;
                    background: $text;
                    color: $white;
                    font-size: 12px;
                    top: -5px;
                    right: -5px;
                    height: 25px;
                    width: 25px;
                    line-height: 25px;
                    text-align: center;
                    border-radius: 50%;
                    font-family: 'Lato', sans-serif;
                }
            }
        }

        .logo {
            height: 60px;
            margin: 20px 0;
            width: auto;
        }

        .cart-btn {
            top: 20px;

            .amount {
                display: inline-block;
                vertical-align: 13px;
            }
        }

        .user-btn {
            display: flex;
            align-items: center;
            gap: 10px;
            color: white;
            background-color: $color2;
            border-radius: 100px;
            height: 60px;
        }

        //form#searchform {
        //    left: 190px;
        //    top: 15px;
        //    width: 230px;
        //
        //    .form-group {
        //        .form-control {
        //            line-height: 46px;
        //            height: 47px;
        //        }
        //    }
        //
        //    button {
        //        right: 8px;
        //        right: 8px;
        //        top: 8px;
        //        font-size: 18px;
        //    }
        //}

        .thuiswinkel {
            position: absolute;
            right: 280px;
            top: 25px;

            img {
                height: 45px;
                width: auto;
            }
        }

        nav {
            display: block !important;
            margin: 0;
            overflow: visible;

            .container {
                .wrap {
                    height: 60px;
                    align-items: center;
                    display: flex;
                    justify-content: space-between;
                }
            }

            .container .wrap > ul {
                > li {
                    position: relative;
                    display: block;
                    float: left;
                    height: auto;

                    &:first-of-type {
                        > a {
                            padding: 7px 25px 7px 0;
                        }

                        ul {
                            left: -11px;
                        }
                    }

                    > a {
                        position: relative;
                        border-bottom: none;
                        padding: 7px 25px 7px 25px;
                        height: auto;
                        font-size: 16px;

                        &.no-pointer {
                            cursor: default;
                        }

                        &:before {
                            content: none;
                        }

                        .icon-caret-down {
                            position: absolute;
                            top: 55%;
                            right: 10px;
                            margin: 0;
                            transform: translateY(-50%);
                        }
                    }

                    &:last-child {
                        a:after {
                            content: none;
                        }
                    }

                    ul {
                        position: absolute;
                        left: 14px;
                        top: 100%;
                        z-index: 10;
                        opacity: 0;
                        visibility: hidden;
                        background: #FFF;
                        min-width: 250px;
                        border: 1px solid #e5e5e5;
                        transition: all 0.3s;

                        li {
                            position: relative;
                            white-space: nowrap;
                            display: block;
                            transition: none;
                            overflow: visible;
                            height: auto;

                            &:after {
                                display: none;
                            }

                            a {
                                padding: 5px 10px;
                                border-top: none;
                                color: $gray-dark;
                                height: auto;
                                font-size: 18px;

                                &:before {
                                    content: none;
                                }
                            }

                            ul {
                                opacity: 0;
                                visibility: hidden;
                                top: -1px;
                                left: auto;
                                right: 0;
                                transform: translate(100%, 0);
                            }

                            &:hover {
                                > a {
                                    color: $color2;
                                }

                                > ul {
                                    opacity: 1;
                                    visibility: visible;
                                }
                            }
                        }
                    }

                    &:hover {
                        overflow: visible;

                        > a {
                            color: $color2;
                        }

                        > ul {
                            opacity: 1;
                            visibility: visible;
                        }
                    }

                    .icon-caret-down {
                        font-size: 12px;
                        vertical-align: 0px;
                        margin: 10px 0 0 5px;
                    }
                }
            }
        }
    }
}

@media (min-width: $screen-lg-min) {
    header {
        //form#searchform {
        //    left: 200px;
        //    width: 300px;
        //}

        .top-header {
            .top-header-wrap {
                ul {
                    li {
                        a {
                            font-size: 17px;
                        }
                    }
                }
            }
        }

        .middle-header {
            height: 100px;
        }

        .thuiswinkel {
            right: 300px;
        }

        .feedbackcompany {
            left: 700px;
        }

        nav {
            .container .wrap > ul {
                > li {

                    > a {

                        font-size: 18px;
                    }
                }
            }
        }
    }
}

@media (min-width: 1400px) {
    header {
        //form#searchform {
        //    width: 400px;
        //}

        .thuiswinkel {
            right: 280px;
        }

        .feedbackcompany {
            left: 800px;

            .top {
                .amount {
                    display: inline-block;
                }
            }
        }
    }
}