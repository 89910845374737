#parent {
    .childpages {
        .childpage {
            position: relative;
            display: block;
            box-shadow: 0 0 10px rgba(#000, 0.1);
            margin-bottom: 30px;

            img {
                width: 100%;
            }
            .content {
                position: absolute;
                bottom: 0;
                left: 0;
                width: 100%;
                text-align: center;
                padding: 5% 15px;
                background: rgba(#fff, 0.8);
                transition: all 300ms ease;

                h3 {
                    margin: 0;
                    color: #000;
                    transition: all 300ms ease;
                }
            }

            &:hover {
                .content {
                    background: rgba($color2, 0.8);

                    h3 {
                        color: #fff;
                    }
                }
            }
        }
    }
}