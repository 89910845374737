.servicepunt {
    padding-bottom: 25px;
    border-bottom: 1px solid #eee;
    margin-bottom: 30px;

    &:first-of-type {
        padding-top: 30px;
        border-top: 1px solid #eee;
        margin-top: 30px;
    }

    &:last-of-type {
        padding-bottom: 0;
        border-bottom: none;
        margin-bottom: 0;
    }
}
