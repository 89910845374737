@mixin clearfix() {
    &::after {
        display: block;
        content: "";
        clear: both;
    }
}

* {
    outline: none;
}

html{
    scroll-behavior: smooth;
}

.container{
    &:before{
        display: none;
    }
    &:after{
        display: none;
    }
}

img {
    pointer-events: none;
}

body {
    font-family: 'sofia-pro', sans-serif;
    font-size: 14px;
    line-height: 1.6;
    overflow-y: scroll;
    scroll-behavior: smooth;
}

.container {
    max-width: 1440px;
    width: initial;
    position: relative;
}

#move-banner {
    background: #FFF2EA;
    padding: 20px;
    text-align: center;
    margin-bottom: 15px;
    border-radius: 5px;
    .content {
        h2 {
            color: $color2;
        }
        p {
            color: #3E3E3E;
            margin: 0;
        }
    }
}

a {
    color: $color2;
    cursor: pointer;
    &:hover,
    &:focus {
        color: #000;
        text-decoration: none;
        outline: none;
    }
}

h1,
h2 {
    font-family: 'sofia-pro', serif;
    font-size: 26px;
    margin: 0 0 20px 0;
    font-weight: 700;
    line-height: 1.3;
    color: $text;
    .grey {
        color: $grey;
    }
}

.color2 {
    color: $color2;
}

h2 {
    font-size: 22px;
    margin: 0 0 10px 0;
}

p {
    margin-bottom: 20px;
}

.d-flex{
    display: flex;
}

.d-block{
    display: block;
}

.order-1{
    order:1;
}

.order-2{
    order:2;
}

.justify-content-end{
    justify-content: flex-end;
}

.justify-content-between{
    justify-content: space-between;
}

.flex-column{
    flex-direction: column;
}

.modal-dialog{
    border-radius: 10px;
    overflow: hidden;
    .modal-content{
        border: none;
    }
}

form{
    margin-block-end: unset!important;
}

.wrapper {
    padding: 30px 0;
    &.wrapper-gray {
        background: #F7F7F7;
    }
    .content {
        background: #f6f2f0;
        padding: 15px;
        border-radius: 7px;
        p:last-child {
            margin-bottom: 0;
        }
        +.content {
            margin-top: 30px;
        }
        h1 {
            margin: 0 0 30px 0;
        }
        .intro {
            font-size: 20px;
            margin: 15px 0;
        }
        p {
            margin: 15px 0;
            line-height: 1.5;
        }
        ul:not(#breadcrumbs) {
            list-style: none;
            margin: 0;
            padding: 0;
            li {
                position: relative;
                padding-left: 20px;
                &:before {
                    position: absolute;
                    left: 0;
                    top: 5px;
                    color: $color2;
                    @extend [class^="icon-"];
                    content: "\e902";
                }
            }
        }

        img {
            max-width: 100%;
            object-fit: contain;
        }
    }
}

.breadcrumb {
    padding: 8px 0;
    margin-top: 20px;
    margin-bottom: 0;
    list-style: none;
    li {
        display: inline-block;
        color: #a4b3bb;
        a {
            color: #a4b3bb;
        }
        +li:before {
            padding: 0 5px;
            color: #a4b3bb;
            content: "/\00a0";
        }
    }
}

.modal-content {
    border-radius: 0px;
}

.no-list{
    list-style: none;
    padding: 0;
    margin: 0;
}

.w100 {
    width: 100%;
}

.h100 {
    height: 100%;
}

.gap-1{
    gap: 5px;
}

.gap-2{
    gap: 10px;
}

.gap-3{
    gap: 15px;
}

.gap-4{
    gap: 20px;
}

.gap-5{
    gap: 25px;
}

.gap-6{
    gap: 35px;
}

.justify-content-center{
    justify-content: center;
}

.flex-wrap{
    flex-wrap: wrap;
}

.d-none{
    display: none!important;
}

.pt-0{
    padding-top: 0!important;
}

.pb-0{
    padding-bottom: 0!important;
}

.align-items-center{
    align-items: center!important;
}

.mb-0{
    margin-bottom: 0!important;
}

.mt-0{
    margin-top: 0!important;
}

.mt-1{
    margin-top: 10px!important;
}

.mt-2{
    margin-top: 20px!important;
}

.mb-2{
    margin-bottom: 20px!important;
}

.mb-3{
    margin-bottom: 30px!important;
}

.mb-4{
    margin-bottom: 40px!important;
}

.br-10{
    border-radius: 10px;
}

.order-1{
    order:1!important;
}

.order-2{
    order:2!important;
}

.bg-white{
    background-color: white;
}

.pull-left {
    float: left !important;
}

.pull-right {
    float: right !important;
}

.text-left,
.text-xs-left {
    text-align: left !important;
}

.text-right,
.text-xs-right {
    text-align: right !important;
}

.text-center,
.text-xs-center {
    text-align: center !important;
}

hr{
    border-top: 1px solid #E6E0DE;
}

@media (min-width: $screen-sm-min) {
    .container {
        max-width: 1440px;
        width: initial;
    }
    .d-sm-flex{
        display: flex!important;
    }
    .d-sm-block{
        display: block!important;
    }
    .text-sm-left {
        text-align: left !important;
    }
    .text-sm-right {
        text-align: right !important;
    }
    .text-sm-center {
        text-align: center !important;
    }
    .flex-sm-row{
        flex-direction: row!important;
    }
    .gap-sm-6{
        gap: 35px;
    }
    .justify-content-sm-left{
        justify-content: flex-start!important;
    }
    .align-items-sm-center{
        align-items: center!important;
    }
    .mt-sm-3{
        margin-top: 30px!important;
    }
}

@media (min-width: $screen-md-min) {
    body{
        font-size: 16px;
        &.fixed{
            .app{
                padding-top: 200px;
            }
        }
    }
    .container {
        max-width: 1440px;
        width: initial;
    }
    #move-banner {
        background: url('../img/links.png') center left no-repeat, url('../img/rechts.png') center right no-repeat, #FFF2EA;
        background-size: contain;
        .content {
            h2 {
                font-size: 24px;
            }
            p {
                font-size: 16px;
            }
        }
    }
    .order-md-1{
        order:1;
    }
    .order-md-2{
        order:2;
    }
    .mb-md-0{
        margin-bottom: 0;
    }
    .flex-md-row{
        flex-direction: row;
    }
    .text-md-left {
        text-align: left !important;
    }
    .text-md-right {
        text-align: right !important;
    }
    .text-md-center {
        text-align: center !important;
    }
    .d-md-block{
        display: block!important;
    }
    .d-md-flex{
        display: flex!important;
    }
    .d-md-none{
        display: none!important;
    }
    .wrapper {
        padding: 45px 0;
        .content {
            padding: 30px;
            p{
                font-size: 16px;
            }
        }
    }
}

@media (min-width: $screen-lg-min) {
    .container {
        max-width: 1440px;
        width: initial;
    }
    .order-lg-2{
        order:2!important;
    }
    .order-lg-1{
        order:1!important;
    }
    .mt-lg-0{
        margin-top: 0 !important;
    }
    .flex-lg-row{
        flex-direction: row!important;
    }
    .text-lg-left {
        text-align: left !important;
    }
    .text-lg-right {
        text-align: right !important;
    }
    .text-lg-center {
        text-align: center !important;
    }
    .d-lg-block{
        display: block!important;
    }
}