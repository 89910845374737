#login {
    //.btn + a {
    //    margin-left: 30px;
    //}

    h1{
        font-size: 24px;
    }

    .login-form{
        background-color: #D8B8A533;
        padding: 40px;
        border-radius: 10px;

        label{
            font-weight: bold;
            color: $text;
        }

        .form-control{
            border: 2px solid #E6E0DE;
        }
    }

    h4{
        font-weight: bold;
    }

    ul:not(#breadcrumbs) {
        list-style: none;
        padding: 0;

        li {
            position: relative;
            padding-left: 25px;
            font-size: 14px;
            line-height: 20px;
            margin-bottom: 10px;

            &:before {
                position: absolute;
                left: 0;
                top: 3px;
                color: $color2;
                @extend [class^="icon-"];
                content: "\e902";
                font-size: 18px;
            }
        }
    }

    .usp {
        text-align: center;

        span {
            display: block;
            font-size: 90px;
            color: $color2;
            margin-bottom: 15px;
        }

        strong {
            display: block;
        }

        p {
            margin-bottom: 0;
        }
    }

    .register_wrapper { 
        //margin-top: 30px;
    }
}

@media (min-width: $screen-sm-min) {
    #login {
        .login-form{
            label{
                font-size: 18px;
            }
        }

        ul:not(#breadcrumbs) {
            li {
                font-size: 16px;
                line-height: 30px;
                margin-bottom: unset;

                &:before {
                    top: 7px;
                    font-size: 18px;
                }
            }
        }
    }
}

@media (min-width: $screen-md-min) {
    #login {
        h1{
            font-size: 40px;
        }
        
        .register_wrapper {
            position: relative;
            padding-left: 32px;
        }
    }
}