form {
    &.form-horizontal {
        .control-label {
            text-align: left;
        }
    }
}

.form-group {
    .form-control {
        box-shadow: none;
        border: 2px solid #D8B8A533;
        border-radius: 10px;
        height: 48px;
        position: relative;
        &.password-input{
            &:after{
                font-family: icomoon, sans-serif;
                content: "\e918";
                position: absolute;
                right: 10px;
                top: 10px;
                cursor: pointer;
            }
        }
        &:focus{
            border: 2px solid $color2 !important;
        }
    }

    .password-container{
        position: relative;
        .toggle-password{
            position: absolute;
            right: 20px;
            top: 50%;
            transform: translateY(-50%);
            cursor: pointer;
            color: #D0CBC9;
            font-size: 18px;
        }
    }
}

@media (min-width: $screen-sm-min) {
    .form-group {
        a{
            font-size: 18px;
            transition: all .3s;
        }
        label{
            font-weight: 100;
            font-size: 16px;
        }
        .form-control {
            height: 60px;
            font-size: 16px;
        }
    }
}