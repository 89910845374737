#sports {
    .sports {
        margin-bottom: 30px;

        .sport {
            position: relative;
            display: block;
            margin-top: 30px;

            img {
                display: block;
                width: 100%;
            }

            h3 {
                position: absolute;
                left: 0;
                bottom: 0;
                width: 100%;
                display: block;
                background: rgba(255, 255, 255, 0.8);
                text-align: center;
                font-size: 22px;
                padding: 50px 0 30px 0;
                margin: 0;
                color: #000;
                font-weight: bold;
                transition: all 0.3s;

                span {
                    display: block;
                    position: absolute;
                    left: 50%;
                    top: -30px;
                    margin-left: -30px;
                    width: 60px;
                    height: 60px;
                    line-height: 60px;
                    border-radius: 30px;
                    background: $color2;
                    transition: all 0.3s;
                    text-align: center;
                    font-size: 30px;
                    transition: all 0.3s;
                    color: #FFF;
                }
            }

            &:hover {
                h3 {
                    background: rgba($color2, 0.8);
                    color: #FFF;

                    span {
                        background: #FFF;
                        color: $color2;
                    }
                }
            }
        }
    }
}