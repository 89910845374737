#products.sport {
    figure#quote {
        position: relative;

        img {
            width: 100%;
        }

        figcaption {
            position: relative;
            z-index: 1;
            background: #000;
            color: #FFF;
            text-align: center;
            padding: 20px 15px;
            margin: -45px 30px 45px 30px;

            h3, h4 {
                margin: 0;
            }

            h3 {
                font-weight: bold;
                margin-bottom: 15px;
            }
        }
    }
}