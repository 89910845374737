#club {
    aside {
        ul {
            li {
                padding-left: 0 !important;

                &:before {
                    content: none !important;
                }

                &:not(:last-child) {
                    border-bottom: 1px solid #DDD;
                }

                a {
                    display: block;
                    padding: 10px 0;
                }
            }
        }
    }

    dl {
        margin: 30px 0;

        dt {
            text-align: left;
        }
    }

    .address {
        margin-bottom: 30px;
        border: 1px solid #DDD;
        padding: 15px;
        border-radius: 5px;

        .btn{
            height: 48px;
        }
    }

    #clubinfo {
        background: #F6F2F0;
        text-align: center;

        .icon-tag {
            display: inline-block;
            color: $color2;
            font-size: 72px;
            margin: 15px 0;
        }

        p {
            margin-bottom: 30px;

            strong {
                span {
                    color: $color2;
                }
            }
        }
    }

    .content.text {
        text-align: center;

        .icons {
            padding: 50px 0 0;
    
            [class^=icon-] {
                font-size: 40px;
                color: $color2;
                font-weight: 700;
                margin-bottom: 15px;
            }
        }
    }

    .discount-label {
        position: relative;

        span {
            color: $color2;
            position: absolute;
            top: calc(50% - 1px);
            left: calc(50% - 7px);
            transform: translate(-50%, -50%);
        }
    }
}

@media (min-width: $screen-md-min) {
    #club{
        dl{
            font-size: 16px;
            dt, dd{
                line-height: 2;
            }
        }
        aside{
            ul{
                li{
                    a{
                        font-size: 16px;
                    }
                }
            }
        }
    }
}