#news {
    .equalHeights{
        display: flex;
        flex-wrap: wrap;
    }
    .article {
        display: inline-block;
        height: auto;

        a{
            height: 100%;
            display: inline-block;
        }

        img {
            width: 100%;
            box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
            height: auto;
            border-radius: 10px 10px 0 10px;
        }
        .date {
            font-size: 16px;
            color: #000;
            margin: 5px 0 0;
            display: block;
            font-weight: bold;
        }
        h3 {
            font-size: 20px;
            font-weight: bold;
            margin: 0;
            margin-top: 10px;
            line-height: 24px;
            margin-bottom: 10px;
            color: $color2;
            transition: all 300ms ease;
        }

        p{
            color: $black;
        }

        &:hover {
            h3 {
                color: $black;
            }
        }

        &.col-md-6 {
            h3 {
                font-size: 28px;
            }
        }
    }
}