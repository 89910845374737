#cart,
#order {
    h1 {
        .count {
            color: #a4b3bb;
        }
    }

    .olfit-tip{
        width: 100%;
        background: #F6F2F0;
        border-radius: 3px;
        padding: 10px 20px;
        text-align: center;
        gap: 5px;
        font-size: 16px;
        margin-bottom: 20px;
        i{
            margin-right: 5px;
            position: relative;
            top: 2px;
            color: $text;
            opacity: .5;
        }
        a{
            text-decoration: underline;
            color: $text;
        }
    }

    .olfit-club {
        float: right;
        line-height: 33px;
    }

    h4 {
        font-weight: 700;
        margin-top: 0;
    }

    table {
        width: 100%;

        thead {
            display: none;

        }

        tbody {
            tr {
                position: relative;
                .actions{
                    position: absolute;
                    top: 0;
                    right: 0;
                }
                td {
                    display: block;
                    padding: 5px 0;

                    &.product {
                        a {
                            color: $text;
                            font-size: 16px;
                            font-weight: 600;
                        }

                        .options {
                            margin-top: 5px;

                            .option {
                                display: inline-block;
                                position: relative;
                                margin-right: 15px;

                                &:after {
                                    content: '/';
                                    position: absolute;
                                    right: -10px;
                                }

                                &:last-child {
                                    &:after {
                                        display: none;
                                    }
                                }
                            }
                        }
                    }

                    &.delivery {
                        font-weight: bold;
                        color: $color2;
                    }

                    &.quantity {
                        .quantity-wrap{
                            display: flex;
                            flex-direction: column;
                            align-items: flex-start;
                        }
                        .shipping_time{
                            color: white;
                            background: $color2;
                            border-radius: 10px 10px 10px 0;
                            font-size: 12px;
                            padding: 2px 10px;
                            position: relative;
                            left: 20px;
                            top: 1px;
                            &:before{
                                position: relative;
                                top: 1px;
                                margin-right: 5px;
                            }
                            &.longer{
                                &:before{
                                    content: '\e910';
                                    font-family: icomoon,sans-serif;
                                }
                            }
                            &.direct{
                                background-color: #3D9558;
                                &:before{
                                    content: '\e91b';
                                    font-family: icomoon,sans-serif;
                                }
                            }
                            &.never{
                                background-color: #A53D00;
                                &:before{
                                    content: '\e913';
                                    font-family: icomoon,sans-serif;
                                }
                            }
                        }
                        form {
                            .form-group {
                                margin-bottom: 0;
                                border-radius: 100px;
                                border: 2px solid #D8B8A533;
                                position: relative;
                                height: 48px;
                                width: 120px;
                                font-size: 18px;
                                label{
                                    margin-bottom: 0;
                                    position: absolute;
                                    top: 50%;
                                    left: 20px;
                                    transform: translateY(-50%);
                                    opacity: 0.5;
                                    font-size: 14px;
                                }
                                .form-control {
                                    background-color: transparent;
                                    border: none!important;
                                    position: absolute;
                                    top: 50%;
                                    transform: translateY(-50%);
                                    width: 50%;
                                    right: 0;
                                    font-weight: bold;
                                    color: $text;
                                    font-size: 14px;
                                }
                            }
                        }

                        .input-group{
                            border-radius: 10px;
                            overflow: hidden;
                            .input-group-addon{
                                padding: 6px 10px;
                                border: none;
                                background-color: #f6f2f0;
                                cursor: pointer;
                                color: #8E8E8E;
                            }
                        }
                    }


                    &.actions {
                        text-align: left;

                        .btn {

                            &.remove {
                                background: #F6F2F0;
                                color: #8E8E8E;
                                font-size: 28px;
                                width: 40px;
                                height: 40px;
                                border-radius: 3px;
                                padding: unset;

                                span {
                                    display: inline-block;
                                    position: relative;
                                    top: -3px;
                                }
                            }

                            &:hover {
                                background: $color2;
                                color: white;
                            }
                        }
                    }
                }
            }
        }
    }

    dl {
        dt {
            float: left;
            clear: left;
            text-align: right;
            white-space: nowrap;
            margin-right: 15px;
        }
    }

    .loader {
        padding: 50px 0;
    }

    .empty-cart {
        background-color: $color2;
        padding: 30px 0;
        border-radius: 10px;

        a{
            color: white;
        }

        h2 {
            font-size: 18px;
            margin: 0;
            color: white;

            a {
                text-decoration: underline;
            }
        }
    }

    .summary {
        padding-top: 20px;
        font-size: 14px;
        border-top: 1px solid #F6F2F0;
    }

    .discount-coupon {
        .discount-btn{
            background-color: #FF630826;
            border-radius: 100px;
            height: 48px;
            display: inline-flex;
            align-items: center;
            justify-content: center;
            gap: 10px;
            font-size: 18px;
            color: $color2;
            font-weight: bold;
            padding: 0 20px;
            transition: all .3s;
            &:hover{
                background-color: $color2;
                color: white;
                i{
                    color: $color2;
                    background-color: white;
                }
            }
            i{
                font-size: 16px;
                color: white;
                background-color: $color2;
                width: 20px;
                height: 20px;
                border-radius: 100%;
                display: flex;
                align-items: center;
                justify-content: center;
                transition: all .3s;
            }
        }

        .form-control{
            height: 48px;
            border-radius: 10px;
            border: 2px solid #D8B8A533;
            box-shadow: none;
        }

        .code {
            font-size: 18px;

            b {
                font-family: Consolas, "Liberation Mono", Courier, monospace;
            }
        }

        .remove {
            font-size: 28px;
            font-weight: 700;
            line-height: 26px;
            color: #000;
        }
    }

    .separator {
        border-bottom: 1px solid $color1;
    }

    .checkout {
        padding: 10px 0 20px 0;
        font-size: 14px;

        .total {
            font-weight: 700;
            margin-bottom: 20px;
        }
    }

    input[name="quantity"] {
        text-align: center;
    }

}

#cart-clerk {
    padding: 50px 0;
    background: $color1;

    .gerelateerdproduct {
        background: #fff;
        border-color: darken($color1, 1%);
    }
}

@media (min-width: $screen-sm-min) {
    #cart,
    #order {
        h1 {
            font-size: 40px;
        }

        .checkout, .summary{
            font-size: 18px;
        }

        .discount-coupon {
            .discount-btn{
                height: 60px;
            }

            .form-control {
                height: 60px;
                border-radius: 10px;
                border: 2px solid #D8B8A533;
                font-size: 18px;
            }
        }

        table {
            width: 100%;

            tbody {
                tr {
                    td {
                        padding: 40px 0;
                        &.quantity {
                            .shipping_time {
                                font-size: 14px;
                                left: 25px;
                            }

                            form{
                                .form-group{
                                    height: 60px;

                                    label{
                                        font-size: 18px;
                                    }

                                    .form-control{
                                        font-size: 18px;
                                    }
                                }
                            }
                        }
                        &.actions{
                            position: relative;
                        }
                    }
                }
            }

            thead {
                display: table-header-group;

                th {
                    border: none;
                    &.quantity {
                        width: 150px;
                    }
                }
            }

            tbody {
                tr {
                    td {
                        display: table-cell;
                        font-size: 18px;

                        img{
                            width: 148px;
                        }

                        &.product{
                            font-size: 18px;
                            font-weight: normal;
                            a{
                                font-weight: bold;
                                font-size: 18px;
                            }
                        }

                        &.actions {
                            text-align: right;
                        }
                    }
                }
            }

            th, td {
                border-top: 1px solid #F6F2F0;
            }
        }
    }
}