.krasactie{
    background-color: #f6f2f0;
    padding: 20px;
    border-radius: 5px;
    margin: 30px 0;
    h1{
        margin: 0 0 20px 0!important;
    }

    p{
        font-size: 18px!important;
        font-weight: bold;
    }

    .alert{
        text-align: center;
    }
}

@media (min-width: $screen-lg-min) {
    .krasactie {
        h1 {
            font-size: 40px;
            text-align: center;
        }

        p{
            text-align: center;
            margin-bottom: 20px!important;
            font-size: 30px!important;
        }
    }
}