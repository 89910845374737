#breadcrumbs {
    margin: 0 0 30px 0;
    padding: 0;
    
    li {
        display: none;
        position: relative;
        //color: $color2;
        font-size: 16px;
        padding: 0 10px;
        font-weight: 100;

        &:after {
            content: '\e90a';
            font-family: 'icomoon', sans-serif;
            position: absolute;
            left: -5px;
            top: 6px;
            font-size: 10px;
            color: #2C1B12;
            opacity: .5;
        }

        &:first-child {
            padding-left: 0;

            &:after {
                display: none;
            }
        }

        a {
            color: #2C1B12;
            opacity: .5;
        }

        &:last-child {
            color: #2C1B12;
            opacity: .5;
        }
    }
}

@media (min-width: $screen-md-min) {
    #breadcrumbs {
        li {
            display: inline-block;
        }
    }
}