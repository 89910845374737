#olfitProfessionalTertiaire {
  overflow: hidden;
  h2 {
    font-size: 24px;
    font-weight: 900;
    @media (min-width: $screen-lg-min) {
      font-size: 30px;
    }
  }

  h3 {
    color: white;
    font-weight: 900;
    margin-top: 5px;
    font-size: 18px;
  }

  sub{
    display: inline-block;
  }

  h6 {
    font-size: 16px;
    color: $color2;
    font-weight: 900;
    @media (min-width: $screen-lg-min) {
      font-size: 20px;
    }
  }

  p {
    font-size: 16px;
    line-height: 23px;
    @media (min-width: $screen-lg-min) {
      font-size: 18px;
      line-height: 27px;
    }
  }

  .upper-text {
    text-align: center;

    h1 {
      font-size: 30px;
      color: #3E3E3E;
      font-weight: 900;
      @media (min-width: $screen-lg-min) {
        font-size: 40px;
      }
    }
  }

  .middle-text {
    text-align: center;
    @media (min-width: $screen-md-min) {
      text-align: left;
    }

    .quote {
      border-radius: 10px;
      background-color: #fff;
      text-align: center;
      padding: 30px;
      position: relative;
      margin-bottom: 20px;
      @media (min-width: $screen-md-min) {
        margin-bottom: 0;
      }

      .quote-left {
        position: absolute;
        left: 20px;
        top: 20px;
        z-index: 1;
      }

      .quote-right {
        position: absolute;
        right: 20px;
        bottom: 20px;
        z-index: 1;
      }

      .top {
        font-style: italic;
        font-size: 20px;
        z-index: 2;
        position: relative;
      }

      .bottom {
        z-index: 2;
        position: relative;

        p {
          font-size: 16px;
          font-weight: bold;
          margin-bottom: 0;
        }

        span {
          font-size: 16px;

          a {
            font-weight: bold;
          }
        }
      }
    }
  }

  .steps {
    .top {
      text-align: center;
    }

    .bottom {
      .btn-wrap{
        text-align: center;
      }

      ul{
        font-size: 16px;
        list-style-position: inside;
        @media (min-width: $screen-lg-min) {
          font-size: 18px;
        }
      }
      .image-block{
        background-color: white;
        border-radius: 10px;
        overflow: hidden;
        margin-bottom: 30px;
        flex-direction: column;
        @media (min-width: $screen-md-min) {
          flex-direction: row;
        }
        .text{
          padding: 30px;
        }
        img{
          @media (min-width: $screen-md-min) {
            width: 50%;
          }
          object-fit: cover;
        }
      }
      .step {
        display: flex;
        flex-direction: column;
        @media (min-width: $screen-md-min) {
          flex-direction: row;
          gap: 40px;
        }
        &:not(:last-child) {
          margin-bottom: 30px;
        }

        .inset{
          @media (min-width: $screen-md-min) {
            width: 45%;
            &.right{
              margin-left: 20px;
              margin-bottom: unset!important;
            }
            &.left{
              margin-bottom: unset!important;
            }
          }
          &.left{
            float: left;
            margin-right: 20px;
            &.more{
              margin-right: 40px;
            }
          }
          &.right{
            float: right;
            margin-bottom: 20px;
          }
          p{
            font-size: 14px;
            line-height: 23px;
            margin-top: 10px!important;
          }
          img{
            width: 100%;
            border-radius: 10px;
          }
        }

        &:nth-child(even) {
          align-items: flex-end;
          @media (min-width: $screen-md-min) {
            flex-direction: row-reverse;
            align-items: flex-start;
            .text{
              &:before{
                left: auto;
                right: 0;
                transform: matrix(0.71, -0.71, 0.71, 0.71, 0, 0) translateX(50%);
              }
            }
          }
        }

        .number {
          background-color: $color2;
          font-size: 30px;
          color: white;
          font-weight: 900;
          border-radius: 100%;
          width: 80px;
          height: 80px;
          display: flex;
          align-items: center;
          justify-content: center;
          flex-shrink: 0;
          margin-bottom: -20px;
          z-index: 1;
          @media (min-width: $screen-md-min) {
            margin-bottom: 0;
          }
        }

        .text {
          background-color: white;
          border-radius: 5px;
          padding: 30px;
          position: relative;
          @media (min-width: $screen-md-min) {
            &:before{
              content: '';
              width: 20px;
              height: 20px;
              background-color: white;
              transform: matrix(0.71, 0.71, -0.71, 0.71, 0, 0) translateX(-50%);
              position: absolute;
              left: 0;
              top: 40px;
            }
          }
          p{
            margin: 0;
          }
        }
      }
    }
  }

  .video{
    .video-wrap{
      position: relative;
      cursor: pointer;
      .play{
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
      }
    }
  }

  .companies{
    #companySlider{
      background-color: white;
      border-radius: 10px;
      padding: 45px 0;
      margin-top: 20px;
      .slick-list{
        margin: 0 -30px;
      }
      .slick-slide{
        margin: 0 30px;
        display: flex;
        height: 100px;
        align-items: center;
        justify-content: center;
        img{
          display: block;
          max-height: 100px;
          width: auto;
          max-width: 100%;
        }
      }
    }
  }

  .how{
    p{
      b{
        color: $color2;
      }
    }
    span{
      text-align: right;
      margin-top: 20px;
      display: block;
      br{
        display: none;
        @media (min-width: $screen-md-min) {
          display: block;
        }
      }
      a{
        font-weight: bold;
      }
    }
  }

  .cta {
    background-color: $color2;
    color: #fff;
    overflow: hidden;

    .btn{
      color: black;
    }

    p{
      margin: 15px 0;
      a{
        text-decoration: underline;
        color:white;
      }
    }

    .row {
      display: flex;
      flex-direction: column-reverse;
    }

    .info{
      padding: 40px 0;
    }

    .img-wrap{
      height: 50%;
      display: flex;
      align-items: flex-end;
      justify-content: center;
      .bg{
        background: transparent radial-gradient(closest-side at 50% 50%, #FFFFFF 0%, #FF6308 100%) 0% 0% no-repeat padding-box;
        opacity: 0.5;
        position: absolute;
        width: 100%;
        height: 100%;
        z-index: 0;
      }
      img{
        width: 50%;
        position: relative;
      }
    }

    h3 {
      color: #fff;
    }

    ul {
      list-style: none;
      padding: 0;
      margin: 0;
      line-height: 28px;

      li {
        a {
          color: #fff;

          i {
            margin-right: 10px;
          }
        }
      }
    }
  }
}

@media (min-width: $screen-sm-min) {
  #olfitProfessionalTertiaire {
    .cta {
      .row {
        flex-direction: row;
      }

      .img-wrap {
        height: 100%;

        img {
          width: 60%;
        }
      }
    }
  }
}


@media (min-width: $screen-md-min) {
  #olfitProfessionalTertiaire {
    .cta {
      h3{
        font-size: 30px;
      }
      ul{
        font-size: 16px;
      }
      .row {
        display: flex;
        flex-direction: row;
      }

      .img-wrap{
        img{
          width: 70%;
        }
      }
    }
  }
}

