#feedback {
    .summary {
        text-align: center;

        img {
            display: inline-block;
            margin: 0 auto 15px auto;
            width: 100%;
            max-width: 300px;
        }

        .stars {
            display: inline-block;
            margin-right: 15px;
            vertical-align: -5px;
        }

        .score {
            display: inline-block;
            font-size: 22px;
            font-weight: bold;

            small {
                font-size: 14px;
                font-weight: normal;
            }
        }
    }

    .overall_ratings {
        h3 {
            color: #666;
            font-weight: bold;
            font-size: 14px;
            margin-bottom: 45px;
        }

        .stat {
            margin-bottom: 15px;

            p {
                margin-top: 0;
                color: #666;
                font-weight: bold;
            }
        }

        .btn {
            margin-top: 30px;
        }
    }

    .stars {
        display: inline-block;
        position: relative;
        font-size: 16px;
        vertical-align: bottom;

        .icon {
            display: inline-block;
            width: 25px;
            height: 25px;
            border-radius: 50%;
            text-align: center;
            line-height: 25px;
            color: #FFF;
        }

        .percent {
            position: relative;
            z-index: 1;
            white-space: nowrap;
            overflow: hidden;

            .icon {
                background: $color2;
            }
        }

        .background {
            position: absolute;
            left: 0;
            top: 0;
            z-index: 0;

            .icon {
                color: #CCC;
                background: #fff;
                border: 1px solid #CCC;
            }
        }
    }

    .reviews_wrapper {
        margin-top: 30px;

        .review {
            border: 1px solid #DDD;
            margin-bottom: 15px;
            border-radius: 5px;
            padding: 15px;

            .overall_score {
                margin-bottom: 15px;

                .score {
                    margin-left: 5px;
                    font-weight: bold;
                    font-size: 18px;

                    small {
                        color: #CCC;
                        margin-left: 4px;
                        letter-spacing: 2px;
                    }
                }
            }

            .name {
                font-weight: bold;
                font-size: 18px;
            }

            .date {
                float: right;
                color: #999999;
                font-style: italic;
            }
        }
    }
}