#bottombar {
    background-color: #D8B8A533;

    .feedbackcompany {

        .top {
            border-radius: 5px;
            text-align: center;
            display: flex;
            justify-content: space-between;
            align-items: center;
            gap: 7px;
            color: white;
            font-size: 14px;

            a {
                color: white;
                text-decoration: underline;
            }

            .feedback_logo {
                img {
                    width: 30px;
                    height: 30px;
                }
            }

            .stars {
                display: inline-block;
                position: relative;
                font-size: 12px;
                vertical-align: middle;
                color: #fff;

                //.icon {
                //    display: inline-block;
                //    width: 15px;
                //    height: 15px;
                //    border-radius: 50%;
                //    text-align: center;
                //    line-height: 15px;
                //}

                .percent {
                    position: relative;
                    z-index: 1;
                    white-space: nowrap;
                    overflow: hidden;

                    .icon {
                        background: $color2;
                    }
                }

                .background {
                    position: absolute;
                    left: 0;
                    top: 0;
                    z-index: 0;

                    .icon {
                        color: $color2;
                    }
                }
            }

            .score {
                display: inline-block;
                color: #333333;
                font-size: 16px;
                vertical-align: bottom;
                line-height: 19px;
                font-weight: bold;
            }

            .amount {
                color: #333333;
                font-size: 16px;
                vertical-align: bottom;
                line-height: 19px;
            }
        }

        .logo {
            text-align: center;
            padding: 5px 15px;
            background: #F6F2F0;
            margin: 0;
            height: unset;

            img {
                width: 140px;
            }
        }
    }

    .top-bar {
        .logo {
            height: 30px;
        }
    }

    .bottom-bar {
        background-color: $color2;
        padding: 10px 0;
    }

    .bar-parts {
        padding: 40px 0;

        .bar-part {
            width: 100%;
        }

        p {
            font-size: 18px;
        }

        > div {
            &:last-of-type {
                width: 20%;
            }
        }

        .contact-blocks {
            .block {
                border-radius: 10px;
                background-color: white;
                padding: 0 30px;
                color: $text;
                height: 135px;
                transition: all .2s;

                &:hover {
                    box-shadow: 0 3px 6px #EAD1C1;
                    transform: scale(1.05);
                }

                .top {
                    flex-grow: 1;
                    padding-bottom: unset;
                }

                i {
                    color: $color2;
                    font-size: 18px;
                }

                .larger {
                    font-size: 18px;
                    font-weight: bold;
                    padding-bottom: unset;
                }

                span {
                    font-size: 14px;
                    padding-bottom: 10px;
                }
            }
        }
    }

    .service {
        padding: 15px;
        background: rgba($color2, 0.1);
        border-radius: 5px;

        img {
            width: 50px;
        }

        h3 {
            color: $color2;
            font-size: 18px;
            line-height: 22px;
            margin: 0 0 2px 0;
        }

        p {
            color: #8E8E8E;
            font-size: 15px;
            line-height: 18px;
            margin: 0;
        }
    }

    .points {
        display: flex;
        flex-wrap: wrap;
        gap: 25px;

        .point {
            // font-size: 18px;
            display: inline-block;
            line-height: 22px;
            margin-top: 15px;
            font-size: 11px;
            margin-right: 5px;
            text-decoration: none;
            color: #000;

            .icon {
                display: inline-block;
                text-align: center;
                color: #fff;
                // height: 25px;
                // width: 25px;
                border-radius: 50%;
                background: $color2;
                // line-height: 25px;
                // margin-right: 15px;
                margin-right: 5px;
                height: 12px;
                width: 12px;
                line-height: 12px;
                font-size: 7px;
            }

            em {
                font-style: normal;
                color: $color2;
                margin-left: 2px;
            }
        }
    }
}

@media (min-width: $screen-sm-min) {
    #bottombar {

        .feedbackcompany {
            .top {
                padding: 5px 10px 5px;
                font-size: 16px;
            }
        }

        .service {
            max-width: 50%;
            margin: 0 auto;
        }

        .points {
            margin-bottom: 15px;

            .point {
                font-size: 15px;
                margin-right: 15px;

                .icon {
                    height: 25px;
                    width: 25px;
                    line-height: 25px;
                    margin-right: 10px;
                    font-size: 15px;
                }
            }
        }
    }
}


@media (min-width: $screen-md-min) {
    #bottombar {
        overflow: hidden;

        .bar-parts {
            padding: 50px 0;

            .bar-part {
                width: unset;
            }
        }

        .service {
            width: 35%;
            float: left;
        }

        h2 {
            font-size: 40px;
        }

        .points {
            padding-left: 15px;
            float: left;
            width: 65%;

            .point {
                //width: 50%;
                float: left;
                margin-right: 0;
                padding-right: 0;
                font-size: 18px;
            }

            em {
                font-size: 16px;
            }
        }
    }
}

@media (min-width: $screen-lg-min) {
    #bottombar {
        .service {
            width: 30%;
        }

        .feedbackcompany {
            .top {
                font-size: 18px;
            }
        }

        .bar-parts {
            padding: 50px 0 0 0;
        }

        .points {
            width: 70%;

            .point {
                //width: 25%;
                text-align: left;
                margin-top: 0;
            }
        }
    }
}