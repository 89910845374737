#infobar {
    padding: 40px 0;

    .slick-slide{
        height: auto;
    }

    &.top{
        padding: 20px 0;
        .container{
            .points{
                border: none;
                padding: unset;
            }
        }
    }

    .container {
        .points {
            border-top: 1px solid #E6E0DE;
            border-bottom: 1px solid #E6E0DE;
            padding-top: 40px;
            padding-bottom: 40px;
            width: 100%;
        }
    }

    //.top-usps{
    //    display: flex!important;
    //    flex-wrap: wrap;
    //    justify-content: space-between;
    //    gap: 10px;
    //}

    //.points,
    //.links {
    //    display: inline-block;
    //}

    .advies{
        display: flex !important;
        justify-content: end;
        width: 100%;
        margin-bottom: 0;
    }

    .points {

        .point {
            display: inline-block;
            text-decoration: none;
            font-size: 15px;
            text-align: center;
            color: #000;
            transition: all .3s;

            &:hover{
                color: $color2;
            }

            .icon {
                display: inline-block;
                border-radius: 50%;
                height: 12px;
                width: 12px;
                color: #fff;
                background: $color2;
                line-height: 12px;
                text-align: center;
                margin-right: 5px;
                font-size: 7px;
                font-weight: bold;
            }

            em {
                font-style: normal;
                color: $color2;
                margin-left: 2px;
            }
        }
    }

    .links {
        float: right;

        nav {
            ul {
                margin: 0;
                padding: 0;

                li {
                    position: relative;
                    display: inline-block;
                    margin-right: 20px;
                    
                    > span {
                        cursor: default;
                    }

                    > a,
                    > span {
                        font-weight: bold;
                        color: #000;
                        font-size: 16px;

                        .icon {
                            font-size: 10px;
                            color: $color2;
                            margin-left: 5px;
                        }
                    }

                    &:hover {
                        > ul {
                            visibility: visible;
                            opacity: 1;
                        }
                    }

                    &.service {
                        a {
                            color: $color2;

                            img {
                                width: 50px;
                                margin-left: 15px;
                            }
                        }
                    }
                    > ul {
                        position: absolute;
                        top: 100%;
                        left: 0;
                        z-index: 3;
                        box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
                        background: #fff;
                        opacity: 0;
                        visibility: hidden;
                        transition: all 300ms ease;
                        
                        li {
                            display: block;
                            margin: 0;

                            a {
                                display: block;
                                font-weight: normal;
                                padding: 5px 10px; 
                                white-space: nowrap;
                                transition: all 300ms ease;

                                &:hover {
                                    background: #eee;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

@media (min-width: $screen-sm-min) {
    #infobar {
        .points {
            .point {
                font-size: 16px;

                .icon {
                    height: 20px;
                    width: 20px;
                    line-height: 20px;
                    font-size: 12px;
                }

                em {
                    font-size: 16px;
                }
            }
        }
    }
}

@media (min-width: $screen-md-min) {
    #infobar{
        padding: 25px 0;

        .links{
            top: 15px;
            position: relative;
        }

        .container{
            .points{
                display: flex;
                justify-content: space-between;
            }
        }

        .advies{
            margin-top: 0;
            margin-bottom: 10px;
        }
    }
}