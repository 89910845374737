#adviesgesprek {
  overflow: hidden;

  h1 {
    font-size: 30px;
    color: #3E3E3E;
    font-weight: 900;
    @media (min-width: $screen-lg-min) {
      font-size: 40px;
    }
  }

  h2 {
    font-size: 24px;
    font-weight: 900;
    @media (min-width: $screen-lg-min) {
      font-size: 30px;
    }
  }

  h6 {
    font-size: 16px;
    color: $color2;
    font-weight: 900;
    @media (min-width: $screen-lg-min) {
      font-size: 20px;
    }
  }

  p {
    font-size: 16px;
    line-height: 23px;
    @media (min-width: $screen-lg-min) {
      font-size: 18px;
      line-height: 27px;
    }
  }

  .card{
    background-color: white;
    border-radius: 10px;
    overflow: hidden;
    .left{
      padding: 30px;
      flex:1;
      ul{
        font-size: 18px;
        padding: 0;
        margin: 0;
        list-style: none;
      }
    }
    .right{
      @media (min-width: $screen-md-min) {
        width: 30%;
      }
      img{
        width: 100%;
      }
    }
  }

  .block {
    background-color: $color2;
    padding: 30px 30px 0 30px;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    h2, p, ul, li, a {
      color: #fff;
    }

    .bottom {
      display: flex;
      gap: 20px;
      flex-direction: column-reverse;
      @media (min-width: $screen-lg-min) {
        flex-direction: row;
      }
      .left {
        .img-wrap{
          position: relative;
          width: 235px;
          .bg{
            background: transparent radial-gradient(closest-side at 50% 50%, #FFFFFF 0%, #FF6308 100%) 0% 0% no-repeat padding-box;
            opacity: 0.5;
            position: absolute;
            width: 100%;
            height: 100%;
            z-index: 0;
          }
          img {
            z-index: 1;
            position: relative;
            width: 100%;
          }
        }
      }

      .right {
        display: flex;
        flex-direction: column;
        gap: 20px;
        img {
          width: 160px;
          border-radius: 5px;
          box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
          background-color: white;
          padding: 5px;
        }
        ul {
          list-style: none;
          padding: 0;
          margin: 0;
          li{
            font-size: 16px;
            i{
              width: 50px;
              margin-right: 10px;
            }
          }
        }
      }
    }
  }

  form{
    padding: 30px;
    border-radius: 10px;
    background-color: white;
    .mb{
        margin-bottom: 20px;
    }
    label{
      font-size: 16px;
    }
    input{
      height: 48px;
    }
    input,textarea{
      padding: 0 15px;
      border-radius: 5px;
      border: 1px solid #ccc;
      width: 100%;
    }
    textarea{
      min-height: 90px;
      padding: 15px;
    }
  }
}