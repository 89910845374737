#injuries {
    aside {
        ul {
            li {
                padding-left: 0 !important;

                &:before {
                    content: none !important;
                }

                &:not(:last-child) {
                    border-bottom: 1px solid #DDD;
                }

                a {
                    display: block;
                    padding: 15px 0;
                }
            }
        }
    }

    .list {
        margin: 30px -15px 0 -15px !important;

        li {
            padding-left: 15px !important;
            margin-top: 15px;

            &:before {
                content: none !important;
            }

            a {
                display: block;
                padding: 15px;
                border: 1px solid #DDD;
                
                &:hover {
                    background: #F5F5F5;
                }
            }
        }
    }

    .btn-back {
        margin-top: 30px;
    }
}