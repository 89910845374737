#register {
    .usp {
        text-align: center;
        margin-bottom: 30px;

        span {
            display: block;
            font-size: 90px;
            color: $color2;
            margin-bottom: 15px;
        }

        strong {
            display: block;
        }

        p {
            margin-bottom: 0;
        }
    }

    form{
        background-color: #f6f2f0;
        padding: 30px;
        border-radius: 10px;
    }
}

@media (min-width: $screen-sm-min) {
    #register {
        .usp {
            margin-bottom: 0;
        }

        h1{
            font-size: 40px;
        }
    }
}