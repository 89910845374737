#home {
    #slideshow {
        margin-bottom: 30px;

        .slide {
            position: relative;
            border-radius: 5px;
            overflow: hidden;

            img {
                width: 100%;
                height: auto;
            }

            .content {
                position: absolute;
                top: 15px;
                right: 15px;
                background: #FFFFFF;
                border-radius: 5px;
                padding: 15px;
                max-width: calc(100% - 30px);

                h1 {
                    font-size: 14px;
                    line-height: 1;
                    color: #3E3E3E;
                    text-transform: unset;
                    margin: 0 0 10px 0;
                }

                h2 {
                    font-size: 12px;
                    line-height: 1;
                    color: #A5A5A5;
                    text-transform: unset;
                    font-weight: normal;
                    margin: 0;

                    .icon {
                        float: right;
                        margin-left: 15px;
                        transform: translateY(2px);
                        color: $color2;
                    }
                }
            }
        }

        .slick-dots {
            position: absolute;
            padding: 0;
            margin: 0;
            left: 15px;
            bottom: 15px;
            text-align: center;
            width: calc(100% - 30px);

            li {
                display: inline-block;
                list-style-type: none;

                button {
                    font-size: 0px;
                    border-radius: 50%;
                    height: 10px;
                    width: 10px;
                    display: block;
                    padding: 0;
                    margin: 0 15px 0 0;
                    border: 1px solid #F6F2F0;
                    background: #fff;
                }

                &.slick-active {
                    button {
                        background: #3E3E3E;
                        border-color: #8E8E8E;
                    }
                }
            }
        }
    }

    h2 {
        color: $text;
    }

    img.verhuizing {
        display: block;
        width: 100%;
        border-radius: 5px;
    }

    #readMore{
        .more-text{
            overflow: hidden;
            opacity: 0;
            max-height: 0;
            transition: all .3s;
            padding-top: 20px;
            &.active{
                opacity: 1;
                max-height: 1000px;
            }
        }
    }

    .image-wrap {
        border-radius: 10px;
        overflow: hidden;
        height: 100%;
        max-height: 460px;
        align-items: start;
        position: relative;
        display: inline-flex;

        .content-wrap{
            max-width: 80%;
        }

        &:hover {
            .content {
                .arrow {
                    background-color: white;

                    i {
                        color: $color2;
                    }
                }
            }
        }

        .content {
            z-index: 1;
            position: absolute;
            bottom: 0;
            padding: 30px;
            width: 100%;

            .arrow {
                border: 2px solid #FFFFFF4D;
                width: 30px;
                height: 30px;
                border-radius: 50%;
                display: flex;
                justify-content: center;
                align-items: center;
                flex-shrink: 0;
                transition: all .2s;

                i {
                    color: white;
                    font-size: 20px;
                }
            }
        }

        .overlay {
            background: transparent linear-gradient(180deg, #A53D0000 0%, #A53D00 100%) 0% 0% no-repeat padding-box;
            mix-blend-mode: multiply;
            position: absolute;
            height: 50%;
            width: 100%;
            bottom: 0;
        }

        .socks {
            position: absolute;
            right: 0;
            top: 60%;
            transform: translateY(-40%);
            height: 60%;
        }

        h2 {
            color: white;
            font-size: 22px;
        }

        h3 {
            color: white;
            font-size: 20px;
            margin: 0;
            font-weight: 600;
        }

        p {
            color: white;
            font-size: 14px;
            line-height: 20px;
        }

        &.alt {
            background: transparent linear-gradient(183deg, #FF6308 0%, #A53D00 100%) 0 0 no-repeat padding-box;
            //cursor: default;
            
            > img{
                position: absolute;
                object-fit: cover;
                object-position: right;
            }

            .content {
                padding: 30px;
                position: relative;
                bottom: unset;
            }

            .arrow{
                justify-self: flex-end;
            }
        }
    }

    #kousenSlider {
        .slick-list {
            margin: 0 -5px;
            padding: 30px 0;

            .slick-slide {
                margin: 0 5px;
            }
        }

        .kousblock {
            border-radius: 10px;
            position: relative;
            overflow: hidden;
            display: inline-flex;
            transition: all .2s;

            &:hover {
                transform: scale(1.05);

                .content {
                    .arrow {
                        background-color: white;

                        i {
                            color: $color2;
                        }
                    }
                }
            }

            .content {
                position: absolute;
                padding: 10px;
                width: 100%;
                bottom: 0;
                z-index: 2;

                .arrow {
                    border: 2px solid #FFFFFF4D;
                    width: 25px;
                    height: 25px;
                    border-radius: 50%;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    flex-shrink: 0;
                    transition: all .2s;

                    i {
                        color: white;
                        font-size: 10px;
                    }
                }

                h4 {
                    color: white;
                    font-size: 14px;
                    margin: 0;
                }
            }

            .overlay {
                background: linear-gradient(180deg, rgba(0, 0, 0, 0%) 0%, rgba(0, 0, 0, 0.25) 100%);
                mix-blend-mode: multiply;
                position: absolute;
                bottom: 0;
                z-index: 1;
                height: 50%;
                width: 100%;
            }
        }
    }

    .banners {
        .banner {
            position: relative;
            display: block;
            overflow: hidden;
            border-radius: 5px;
            margin-bottom: 30px;

            .overlay {
                position: absolute;
                top: 0;
                left: 0;
                height: 100%;
                width: 100%;
                background: rgba(#3E3E3E, 0.5);
                padding: 20px;
                opacity: 0;
                transition: all 300ms ease;

                h2,
                p {
                    color: #fff;
                    margin: 0;
                }

                h2 {
                    font-size: 26px;
                    line-height: 32px;
                    text-transform: unset;
                    margin-bottom: 5px;
                }

                p {
                    font-size: 18px;
                    line-height: 22px;
                }

                .icon {
                    position: absolute;
                    bottom: 20px;
                    right: 20px;
                    color: #fff;
                    font-size: 22px;
                }
            }

            &:hover {
                .overlay {
                    opacity: 1;
                }
            }
        }
    }

    #kousen {
        padding: 50px 0 40px 0;
        overflow: hidden;

        .slider-nav {
            .slick-dots {
                list-style: none;
                margin: 0;
                padding: 0;
                display: flex;
                gap: 5px;

                li {
                    display: flex;
                    align-items: center;

                    &.slick-active {
                        button {
                            background-color: $color2;
                        }
                    }

                    button {
                        font-size: 0;
                        width: 10px;
                        height: 10px;
                        border-radius: 100%;
                        border: none;
                        padding-block: 0;
                        padding-inline: 0;
                        background-color: #D8B8A533;
                    }
                }
            }
        }
    }

    #products {
        padding: 50px 0;
        background: #F6F2F0;

        .product-slider {
            margin: 0 -15px;

            .slick-list {
                padding-top: 15px;
            }

            .product {
                position: relative;
                display: block;
                transition: all 300ms ease;
                margin: 0 15px 15px;

                .top {
                    padding: 15px 0 5px 0;
                    flex-wrap: wrap;

                    .category {
                        font-size: 14px;
                        color: #958D88;
                    }

                    .score {
                        i {
                            font-size: 8px;
                        }
                    }
                }

                .img-wrap {
                    position: relative;
                    display: inline-block;
                    width: 100%;

                    img {
                        width: 100%;
                        height: auto;
                        transition: all .3s;
                        border-radius: 10px 10px 10px 0;
                    }

                    .label {
                        position: absolute;
                        bottom: 0;
                        left: 0;
                        color: #fff;
                        background: $color2;
                        border-radius: 10px 10px 10px 0;
                        text-align: center;
                        font-size: 14px;
                        font-weight: 500;

                        &.black {
                            background: #000000;
                        }

                        span {
                            &.laatste-exemplaren {
                                font-size: 10px;
                            }
                        }
                    }
                }

                span {

                    &.title {
                        color: $text;
                        font-size: 14px;
                        line-height: 20px;
                        font-weight: bold;
                    }

                    &.price {
                        display: block;
                        margin-top: 10px;
                        font-size: 16px;
                        color: $text;
                        font-weight: 500;

                        .sale {
                            font-size: 22px;
                            color: $black;
                            margin-bottom: 0;

                            .old {
                                display: inline-block;
                                font-size: 22px;
                                text-decoration: line-through;
                                font-weight: normal;
                                margin-left: 10px;
                            }
                        }

                        small {
                            display: none;
                            color: $black;
                            font-size: 12px;
                            margin-left: 5px;
                        }
                    }
                }

                .info {
                    transition: all .2s;
                }

                &:hover {
                    transform: scale(1.01);
                    color: $color2;

                    img {
                        box-shadow: 0 3px 6px #EAD1C1;
                    }
                }
            }

            .slick-arrow {
                position: absolute;
                top: 50%;
                transform: translateY(-50%);
                font-size: 30px;
                color: $color2;
                z-index: 100;
                cursor: pointer;

                &.prev {
                    left: 20px;
                }

                &.next {
                    right: 20px;
                }
            }
        }
    }

    #info {
        padding: 50px 0;
        font-size: 18px;

        h2 {
            font-size: 26px;
            margin: 0 0 15px 0;
        }

        .olfit {
            position: relative;
            padding: 15px 15px 40px;
            background: $color2;
            border-radius: 5px;
            display: block;

            h2,
            p {
                color: #fff;
            }

            p {
                margin: 0;
            }

            .icon {
                position: absolute;
                color: #fff;
                right: 15px;
                bottom: 15px;
            }
        }

        .feedback {
            position: relative;
            display: block;
            background: #F6F2F0;
            padding: 15px 15px 40px;
            margin-top: 15px;
            border-radius: 5px;

            h2 {
                color: #3E3E3E;
                margin-bottom: 15px;
            }

            .stars {
                display: inline-block;
                position: relative;
                font-size: 16px;
                vertical-align: bottom;
                margin-bottom: 15px;

                .icon {
                    display: inline-block;
                    width: 25px;
                    height: 25px;
                    border-radius: 50%;
                    text-align: center;
                    line-height: 25px;
                    color: #fff;
                }

                .percent {
                    position: relative;
                    z-index: 1;
                    white-space: nowrap;
                    overflow: hidden;

                    .icon {
                        background: $color2;
                    }
                }

                .background {
                    position: absolute;
                    left: 0;
                    top: 0;
                    z-index: 0;

                    .icon {
                        color: #CCC;
                        background: #fff;
                    }
                }
            }

            .score {
                display: inline-block;
                font-size: 16px;
                vertical-align: bottom;
                line-height: 24px;
                color: #000;

                span {
                    font-size: 12px;
                    color: $color2;
                }
            }

            > .icon {
                position: absolute;
                color: $color2;
                right: 15px;
                bottom: 15px;
            }
        }

        .sports {
            padding: 15px;
            background: #3E3E3E;
            color: #fff;
            margin-top: 15px;
            border-radius: 5px;

            h2 {
                color: #fff;
            }

            select::ms-expand {
                display: none;
            }

            select {
                appearance: none;
                border: none;
                color: #3E3E3E;
                border-radius: 5px;
                width: 100%;
                padding: 3px 15px;

                background: #fff url('/img/select-orange.svg') no-repeat calc(100% - 15px) center;
                background-size: auto 7px;
            }
        }
    }

    #social {
        padding: 0 0 50px;

        h2 {
            color: #3E3E3E;
        }
    }

    #content {
        color: $text;
        font-size: 14px;
        line-height: 20px;
        padding: 40px 0;

        a {
            transition: all .3s;
        }

        h1,
        h2 {
            margin: 0;
            font-size: 15px;
            line-height: 20px;
        }
    }

    #brands {
        background: white;
        padding: 70px 0;
    }

    .sale-content {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        color: #fff;

        span:first-of-type {
            font-size: 16px;
        }

        span:last-of-type {
            font-weight: bold;
            font-size: 20px;
            margin-bottom: 10px;
        }
    }
}

@media (min-width: $screen-sm-min) {
    #home {
        #kousenSlider {
            .slick-list {
                margin: 0 -15px;

                .slick-slide {
                    margin: 0 15px;
                }
            }

            .kousblock {
                .content {
                    padding: 20px 30px;

                    .arrow {
                        width: 48px;
                        height: 48px;

                        i {
                            font-size: 20px;
                        }
                    }

                    h4 {
                        font-size: 20px;
                    }
                }
            }
        }

        #slideshow {
            .slide {
                .content {
                    padding: 25px;

                    h1 {
                        font-size: 26px;
                        line-height: 32px;
                    }

                    h2 {
                        font-size: 22px;
                        line-height: 28px;
                    }
                }
            }
        }

        #products {
            .product {
                margin-bottom: 30px;

                .price {
                    .price {
                        font-size: 20px;
                    }
                }
            }

            .product-slider{
                .product{
                    span {
                        &.title {
                            font-size: 18px;
                            line-height: 27px;
                        }
                    }
                }
            }
        }

        #info {
            .olfit,
            .feedback,
            .sports {
                margin-top: 0;
                padding: 30px;

                > .icon {
                    right: 30px;
                    bottom: 30px;
                }
            }
        }
    }
}

@media (min-width: $screen-md-min) {
    #home {
        h2 {
            font-size: 40px;
        }

        #readMore{
            .more-text{
                overflow: hidden;
                opacity: 1;
                max-height: unset;
                padding-top: unset;
            }
        }

        #content {
            padding: 90px 0;
        }

        #infobar {
            padding: 70px 0;
        }

        #slideshow {
            .slide {
                .content {
                    top: 30px;
                    right: 30px;
                    max-width: calc(100% - 60px);
                }
            }
        }

        #kousen {
            padding: 50px 0 90px 0;
        }

        .image-wrap {
            h2, h3 {
                font-size: 26px;
                line-height: 32px;
            }

            p {
                font-size: 16px;
                line-height: 24px;
            }

            .socks {
                top: 50%;
                transform: translateY(-40%);
                height: 90%;
            }

            .content {
                .arrow {
                    width: 60px;
                    height: 60px;

                    i {
                        font-size: 20px;
                    }
                }
            }

            &.alt {
                background: transparent linear-gradient(183deg, #FF6308 0%, #A53D00 100%) 0 0 no-repeat padding-box;

                .content {
                    position: relative;
                    bottom: unset;
                    padding: 40px;
                }
            }
        }
    }
}

@media (min-width: $screen-lg-min) {
    #home {
        .image-wrap {
            h2, h3 {
                font-size: 30px;
                line-height: 36px;
            }

            p {
                font-size: 20px;
                line-height: 33px;
            }
        }

        .sale-content {
            span:first-of-type {
                font-size: 24px;
            }

            span:last-of-type {
                font-weight: bold;
                font-size: 50px;
                line-height: 50px;
                margin-bottom: 30px;
            }
        }
    }
}

@media (min-width: 1500px) {
    #home {
        #products {
            .product-slider {
                .slick-arrow {
                    transition: all 300ms ease;

                    &.prev {
                        left: -30px;

                        &:hover {
                            left: -35px;
                        }
                    }

                    &.next {
                        right: -30px;

                        &:hover {
                            right: -35px;
                        }
                    }
                }
            }
        }
    }
}