#faq {
    text-align: left;
    h3{
        font-weight: 900;
    }
    ul {
        margin: 0;
        padding: 0;
        list-style: none;
        li {
            margin-bottom: 10px;

            .question {
                position: relative;
                font-size: 16px;
                cursor: pointer;
                color:#3E3E3E;
                transition: all 0.3s;
                display: flex;
                gap: 15px;
                align-items: center;

                i{
                    background-color: $color2;
                    color: white;
                    width: 20px;
                    height: 20px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    border-radius: 100%;
                    font-size: 12px;
                    transition: all .3s;
                }

                p{
                    width: 90%;
                }

                span {
                    position: absolute;
                    right: 30px;
                    top: 50%;
                    transform: translate(0, -50%);
                    width: 19px;
                    height: 5px;
                    background: #fff;
                    color:#fff;

                    &:after {
                        content: '';
                        position: absolute;
                        left: 7px;
                        top: -7px;
                        width: 5px;
                        height: 19px;
                        background: #fff;
                        color:#fff;
                    }
                }

                + .answer {
                    border-radius: 10px;
                    background: #f6f2f0;
                    max-height:0;
                    overflow:hidden;
                    transition: all 0.3s;
                    padding: 0 20px;
                    opacity: 0;
                    font-size: 16px;

                    p {
                        &:last-of-type {
                            margin-bottom: 0;
                        }
                    }
                }

                &.active {
                    border-radius: 10px 10px 0 0;
                    span {
                        &:after {
                            content: none;
                        }
                    }

                    i{
                        transform: rotate(-90deg);
                    }

                    + .answer {
                        padding:20px;
                        opacity: 1;
                        border-radius: 0 0 10px 10px;
                        //display: block;
                        max-height: 900px;
                        overflow: auto;
                        margin-top: 10px;
                    }
                }
            }
        }
    }
}

@media (min-width: $screen-lg-min) {
    #faq{
        h1{
            font-size: 40px;
        }
        ul{
            li{
                .question{
                    font-size: 18px;
                    p{
                        width: 100%;
                    }
                }
            }
        }
    }
}