.slider {
  display: inline-block;
  vertical-align: middle;
  position: relative;
}
.slider.slider-horizontal {
  width: 100%;
  height: 5px;
}
.slider.slider-horizontal .slider-track {
  height: 5px;
  width: 100%;
  top: 8px;
  left: 0;
}
.slider.slider-horizontal .slider-selection,
.slider.slider-horizontal .slider-track-low,
.slider.slider-horizontal .slider-track-high {
  height: 100%;
  top: 0;
  bottom: 0;
}
.slider.slider-horizontal .slider-handle.max-slider-handle {
  margin-left: -25px;
}
.slider.slider-horizontal .slider-tick.triangle,
.slider.slider-horizontal .slider-handle.triangle {
  position: relative;
  top: 50%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  border-width: 0 10px 10px 10px;
  width: 0;
  height: 0;
  border-bottom-color: $color1;
  margin-top: 0;
}
.slider.slider-horizontal .slider-tick-container {
  white-space: nowrap;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
}
.slider.slider-horizontal .slider-tick-label-container {
  white-space: nowrap;
  margin-top: 20px;
}
.slider.slider-horizontal .slider-tick-label-container .slider-tick-label {
  padding-top: 4px;
  display: inline-block;
  text-align: center;
}
.slider.slider-horizontal .tooltip {
  -ms-transform: translateX(-50%);
  transform: translateX(-50%);
}
.slider.slider-horizontal.slider-rtl .slider-track {
  left: initial;
  right: 0;
}
.slider.slider-horizontal.slider-rtl .slider-tick,
.slider.slider-horizontal.slider-rtl .slider-handle {
  margin-left: initial;
  margin-right: -10px;
}
.slider.slider-horizontal.slider-rtl .slider-tick-container {
  left: initial;
  right: 0;
}
.slider.slider-horizontal.slider-rtl .tooltip {
  -ms-transform: translateX(50%);
  transform: translateX(50%);
}
.slider.slider-vertical {
  height: 210px;
  width: 20px;
}
.slider.slider-vertical .slider-track {
  width: 10px;
  height: 100%;
  left: 25%;
  top: 0;
}
.slider.slider-vertical .slider-selection {
  width: 100%;
  left: 0;
  top: 0;
  bottom: 0;
}
.slider.slider-vertical .slider-track-low,
.slider.slider-vertical .slider-track-high {
  width: 100%;
  left: 0;
  right: 0;
}
.slider.slider-vertical .slider-tick,
.slider.slider-vertical .slider-handle {
  margin-top: -10px;
}
.slider.slider-vertical .slider-tick.triangle,
.slider.slider-vertical .slider-handle.triangle {
  border-width: 10px 0 10px 10px;
  width: 1px;
  height: 1px;
  border-left-color: $color1;
  border-right-color: $color1;
  margin-left: 0;
  margin-right: 0;
}
.slider.slider-vertical .slider-tick-label-container {
  white-space: nowrap;
}
.slider.slider-vertical .slider-tick-label-container .slider-tick-label {
  padding-left: 4px;
}
.slider.slider-vertical .tooltip {
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}
.slider.slider-vertical.slider-rtl .slider-track {
  left: initial;
  right: 25%;
}
.slider.slider-vertical.slider-rtl .slider-selection {
  left: initial;
  right: 0;
}
.slider.slider-vertical.slider-rtl .slider-tick.triangle,
.slider.slider-vertical.slider-rtl .slider-handle.triangle {
  border-width: 10px 10px 10px 0;
}
.slider.slider-vertical.slider-rtl .slider-tick-label-container .slider-tick-label {
  padding-left: initial;
  padding-right: 4px;
}
.slider.slider-disabled .slider-handle {
  background-image: -webkit-linear-gradient(top, #dfdfdf 0%, #bebebe 100%);
  background-image: -o-linear-gradient(top, #dfdfdf 0%, #bebebe 100%);
  background-image: linear-gradient(to bottom, #dfdfdf 0%, #bebebe 100%);
  background-repeat: repeat-x;
}
.slider.slider-disabled .slider-track {
  background-image: -webkit-linear-gradient(top, #e5e5e5 0%, #e9e9e9 100%);
  background-image: -o-linear-gradient(top, #e5e5e5 0%, #e9e9e9 100%);
  background-image: linear-gradient(to bottom, #e5e5e5 0%, #e9e9e9 100%);
  background-repeat: repeat-x;
  cursor: not-allowed;
}
.slider input {
  display: none;
}
.slider .tooltip.top {
  margin-top: -36px;
}
.slider .tooltip-inner {
  white-space: nowrap;
  max-width: none;
}
.slider .hide {
  display: none;
}
.slider-track {
  position: absolute;
  cursor: pointer;
  background: #FBB47F;
}
.slider-selection {
  position: absolute;
  background: $color1;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  border-radius: 4px;
}
.slider-selection.tick-slider-selection {
  background-image: -webkit-linear-gradient(top, #8ac1ef 0%, #82b3de 100%);
  background-image: -o-linear-gradient(top, #8ac1ef 0%, #82b3de 100%);
  background-image: linear-gradient(to bottom, #8ac1ef 0%, #82b3de 100%);
  background-repeat: repeat-x;
}
.slider-track-low,
.slider-track-high {
  position: absolute;
  background: transparent;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  border-radius: 4px;
}
.slider-handle {
  position: absolute;
  top: -2.5px;
  width: 25px;
  height: 25px;
  border: 1px solid $color1;
  background-color: $white;
  -webkit-box-shadow: inset 0 1px 0 rgba(255,255,255,.2), 0 1px 2px rgba(0,0,0,.05);
  box-shadow: inset 0 1px 0 rgba(255,255,255,.2), 0 1px 2px rgba(0,0,0,.05);
}
.slider-handle.round {
  border-radius: 50%;
}
.slider-handle.triangle {
  background: transparent none;
}
.slider-handle.custom {
  background: transparent none;
}
.slider-handle.custom::before {
  line-height: 20px;
  font-size: 20px;
  content: '\2605';
  color: #726204;
}
.slider-tick {
  position: absolute;
  width: 20px;
  height: 20px;
  background-image: -webkit-linear-gradient(top, #f9f9f9 0%, #f5f5f5 100%);
  background-image: -o-linear-gradient(top, #f9f9f9 0%, #f5f5f5 100%);
  background-image: linear-gradient(to bottom, #f9f9f9 0%, #f5f5f5 100%);
  background-repeat: repeat-x;
  -webkit-box-shadow: inset 0 -1px 0 rgba(0, 0, 0, 0.15);
  box-shadow: inset 0 -1px 0 rgba(0, 0, 0, 0.15);
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  opacity: 0.8;
  border: 0px solid transparent;
}
.slider-tick.round {
  border-radius: 50%;
}
.slider-tick.triangle {
  background: transparent none;
}
.slider-tick.custom {
  background: transparent none;
}
.slider-tick.custom::before {
  line-height: 20px;
  font-size: 20px;
  content: '\2605';
  color: #726204;
}
.slider-tick.in-selection {
  background-image: -webkit-linear-gradient(top, #8ac1ef 0%, #82b3de 100%);
  background-image: -o-linear-gradient(top, #8ac1ef 0%, #82b3de 100%);
  background-image: linear-gradient(to bottom, #8ac1ef 0%, #82b3de 100%);
  background-repeat: repeat-x;
  opacity: 1;
}
