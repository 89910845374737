footer {
    background: white;
    color: rgba($text, 0.8);
    padding: 30px 0 0 0;

    .bottom-wrap{
        padding: 20px 0;
        border: none;
    }

    .icon-topsite{
        font-size: 14px;
        position: relative;
        top: 1px;
    }

    nav {
        overflow: hidden;

        //> ul {
        //    margin: 0 0 30px 0;
        //}

        ul {
            list-style: none;
            padding: 0;
            //margin: 0 0 30px 0;

            &.socials{
                li{
                    a{
                        display: inline-flex;
                        gap: 10px;
                        align-items: center;
                        i{
                            color: $color2;
                        }
                    }
                }
            }

            li {
                h4 {
                    font-weight: bold;
                    font-size: 18px;
                    color: $text;
                    margin: 0 0 20px 0;
                    padding-bottom: 10px;
                    border-bottom: 1px solid #E6E0DE;
                    cursor: pointer;

                    .icon {
                        float: right;
                        transition: all 300ms ease;
                    }

                    &.active {
                        .icon {
                            transform: rotate(-180deg);
                        }
                    }
                }

                > ul {
                    display: none;
                    margin-bottom: 20px;

                    > li {
                        padding: 6px 0;
                        line-height: 1.3;
                    }
                }
                a {
                    color: rgba($text, 0.8);
                    transition: all 300ms ease;
                    font-size: 16px;

                    &:hover {
                        color: $color2;
                    }
                }
            }
        }
    }

    #feedbackcompany {
        max-width: 400px;
        margin: 0 auto;

        .top {
            padding: 5px 10px 10px;
            background: #2A2A2A;

            .stars {
                display: inline-block;
                position: relative;
                font-size: 16px;
                vertical-align: bottom;
                color: #fff;

                .icon {
                    display: inline-block;
                    width: 25px;
                    height: 25px;
                    border-radius: 50%;
                    text-align: center;
                    line-height: 25px;
                }

                .percent {
                    position: relative;
                    z-index: 1;
                    white-space: nowrap;
                    overflow: hidden;

                    .icon {
                        background: $color2;
                    }
                }
                .background {
                    position: absolute;
                    left: 0;
                    top: 0;
                    z-index: 0;

                    .icon {
                        color: #CCC;
                        background: #fff;
                    }
                }
            }

            .score {
                margin-left: 10px;
                display: inline-block;
                color: #fff;
                font-size: 20px;
                vertical-align: bottom;
                line-height: 28px;
            }

            .amount {
                display: inline-block;
                color: $color2;
                font-size: 14px;
                vertical-align: bottom;
                margin-left: 15px;
            }
        }
        .logo {
            text-align: center;
            padding: 10px 0;
            background: rgba(#2A2A2A, 0.5);

            img {
                width: 140px;
            }
        }
    }

    .social {
        margin-bottom: 30px;
        padding-top: 30px;

        .icon {
            display: inline-block;
            width: 45px;
            height: 45px;
            border-radius: 30px;
            background: #ff6308;
            color: #fff;
            line-height: 45px;
            text-align: center;
            font-size: 20px;
            margin-right: 5px;
            transition: all 0.3s;

            &:last-of-type {
                margin-right: 0;
            }

            &:hover {
                background: #fff;
                color: #ff6308;
            }
        }
    }

    #frmNewsletter {
        .form-control {
            height: auto;
            padding: 10px;
            background-clip: padding-box;
            border: none;
            max-width: 360px;
            margin: 0 auto;
        }
    }

    .bottom {
        background: #fff;
        text-align: center;
        color: rgba($text, 0.8);
        font-size: 16px;
        line-height: 35px;

        a {
            color: rgba($text, 0.8);
            transition: all .3s;
            &:hover{
                color: $color2;
            }
        }

        figure {

            a {
                &:last-child {
                    img {
                        margin-right: 0;
                    }
                }
            }

            img {
                height: 26px;

                &.homepay {
                    height: 22px;
                }
                &.sofort {
                    height: 18px;
                }
            }
        }
    }
}

@media (min-width: $screen-sm-min) {
    footer {
        .bottom-wrap{
            border-top: 1px solid #E6E0DE;
        }
        nav {
            > ul {
                overflow: hidden;

                ul {
                    margin-bottom: 0;
                }
            }

            ul{
                li{
                    h4{
                        font-size: 22px;
                    }

                    >ul{
                        margin-bottom: unset;
                    }
                }
            }
        }
        .bottom {
            figure {
                img {
                    height: 38px;

                    &.homepay {
                        height: 22px;
                    }
                    &.sofort {
                        height: 14px;
                    }
                }
            }
        }
    }
}

@media (min-width: $screen-sm-min) {
    footer {
        nav {
            ul {
                li {
                    h4 {
                        border: none;
                        cursor: initial;
                        padding-bottom: unset;

                        .icon {
                            display: none;
                        }
                    }
                    > ul {
                        display: block;
                    }
                }
            }
        }
    }
}

@media (min-width: $screen-md-min) {
    footer {
        padding: 60px 0 0 0;
        ul{
            li{
                a{
                    font-size: 18px;
                }
            }
        }

        .bottom {
            figure {
                margin-top: 0;
            }
        }
    }
}

@media (min-width: $screen-lg-min) {
    footer {
        text-align: left;

        nav {
            > ul {
                margin-bottom: 0;
            }
        }

        //#feedbackcompany {
        //    float: right;
        //}
    }
}