.popmessage {
    position: fixed;
    bottom: 0px;
    right: 30px;
    padding: 15px 30px;
    background: $color1;
    color: #FFF;
    transform: translate(0, 100%);
    transition: all 0.3s;
    font-weight: bold;

    &.active {
        transform: translate(0,0);
    }
}