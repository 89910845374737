#product {
    &.combination{
        //.optionWrapper{
        //    display: none;
        //}

        .optionWrapper{
            h4{
                font-weight: bold;
            }
        }

        //.options{
        //    > div{
        //        &.delivery_time{
        //            &:last-of-type{
        //                display: none;stuks op voorraad.
        //            }
        //        }
        //    }
        //}

        .delivery_time{
            font-size: 16px;
            font-style: unset!important;
            margin: -10px 0 20px 0 !important;
            max-width: 60%;
            .stock-label{
                position: absolute;
                right: 0;
                color: white;
                background-color: #3D9558;
                padding: 2px 5px;
                display: block;
                border-radius: 10px 10px 10px 0;
                font-size: 14px;
            }
        }
    }
    .images-label{
        font-size: 14px !important;
        margin-bottom: 0;
        line-height: 34px;
        font-weight: bold;
    }
    .back {
        font-size: 14px;
        font-weight: bold;

        .icon {
            font-size: 10px;
            color: rgba(#3E3E3E, 0.3);
            margin-right: 8px;
        }

        &:hover {
            color: $color2;
        }
    }

    #selectedVariantDeliveryTime{
        max-width: 60%;

        span{
            &:not(.stock-label){
                color: white;
                background-color: $color2;
                padding: 2px 5px;
                border-radius: 10px 10px 10px 0;
                font-size: 14px;
                display: inline-block;
            }
        }
    }

    .olfit-tip{
        width: 100%;
        background: #F6F2F0;
        border-radius: 3px;
        padding: 10px 20px;
        font-size: 16px;
        margin: 20px 0;
        i{
            margin-right: 5px;
            position: relative;
            top: 2px;
            color: $text;
            opacity: .5;
        }
        a{
            text-decoration: underline;
            color: $text;
        }
    }

    .reviews{
        scroll-margin-top: 200px;
        h3{
            position: relative;
            .stars{
                position: relative;
                top: -3px;
            }

            span{
                color: #2C1B12;
                opacity: 0.5;
                font-size: 16px;
            }
        }
        .stars{
            display: block;
            .icon{
                display: inline-block;
                font-size: 12px;
                color: #e8e8e8;
            }
            .inner{
                position: absolute;
                left: 0;
                top: 0;
                white-space: nowrap;
                overflow: hidden;
                .icon{
                    color: $color2;
                }
            }
        }
    }

    .gallery {
        margin: 30px 0;

        .images {
            z-index: 1;

            span.label {
                position: absolute;
                top: 0;
                left: 0;
                color: #fff;
                font-size: 16px;
                font-weight: 100;
                z-index: 9;
                border-radius: 10px 10px 10px 0;

                &.orange {
                    background: $color2;
                }

                &.black {
                    background: #000000;
                }
            }

            .slide {
                outline: none;
                overflow: hidden;

                a {
                    outline: none;
                    width: 100%;
                    display: inline-block;

                    img {
                        width: 100%;
                        max-width: 100%;
                        height: auto;
                    }
                }

                .icon {
                    position: absolute;
                    bottom: 15px;
                    right: 15px;
                    font-size: 40px;
                    color: $color2;
                }
            }

            .slick-dots {
                padding: 0;
                margin: 0;
                text-align: center;
                transform: translateY(-40px);

                li {
                    list-style: none;
                    display: inline-block;
                    margin-right: 15px;

                    &.slick-active {
                        button {
                            border-color: #8E8E8E;
                            background: #3E3E3E;
                        }
                    }

                    button {
                        padding: 0;
                        display: block;
                        font-size: 0;
                        border: 1px solid red;
                        background: #F6F2F0;
                        height: 10px;
                        width: 10px;
                        border-radius: 50%;
                        transition: all 300ms ease;
                    }
                }
            }
        }
    }

    h1 {
        font-size: 20px;
        margin-bottom: 10px;
    }

    .description-preview {
        p {
            margin-bottom: 15px;
            font-size: 16px;
        }

        .read-more {
            font-weight: bold;
            display: block;

            .icon {
                font-size: 10px;
            }
        }
    }

    .usps {
        padding: 0;
        margin: 0;
        list-style: none;

        .usp {
            position: relative;
            padding-left: 25px;
            margin-bottom: 10px;
            line-height: 22px;
            font-size: 16px;

            &:last-child {
                margin-bottom: 0;
            }

            &:before {
                content: '\e91b';
                position: absolute;
                display: flex;
                align-items: center;
                justify-content: center;
                left: 0;
                top: 5px;
                font-family: 'Icomoon', sans-serif;
                color: white;
                background-color: $color2;
                width: 17px;
                height: 17px;
                border-radius: 50%;
                font-size: 12px;
                font-weight: bold;
            }
        }
    }

    .price {
        color: $text;
        font-size: 24px;
        display: inline-block;
        line-height: 1;
        margin-top: 20px;

        .sale {
            font-size: 22px;
            color: $black;
            margin-bottom: 10px;

            .old {
                font-size: 22px;
                text-decoration: line-through;
                font-weight: normal;
                margin-left: 10px;
            }
        }

        small {
            color: $black;
            font-size: 12px;
            margin-left: 5px;
            display: block;
            margin-top: 5px;
        }
    }

    .tip,
    .by_appointment {
        max-width: 360px;
        padding: 15px;
        background: #f5f5f5;
        font-size: 14px;

        a {
            font-weight: bold;
        }

        p {
            margin-bottom: 0;
        }
    }

    .product-tip {
        .tip {
            display: none;
            position: relative;
            margin: 15px 0;

            &.show {
                display: block;
            }

            &:before {
                content: '';
                position: absolute;
                right: 15px;
                top: -7px;
                display: block;
                width: 15px;
                height: 15px;
                background: #f5f5f5;
                transform: rotate(45deg);
            }
        }
    }

    #options{
        .placeholder{
            margin-top: 10px;
        }
        > div{
            &:last-of-type{
                &.placeholder{
                    display: none!important;
                }
            }
        }
    }

    #addToCartForm,
    #options {
        margin-top: 15px;

        i.danger {
            color: #ff0000;
        }

        .product-tip {
            position: relative;
            width: auto !important;
            display: flex;
            align-items: center;
            height: 0;
            opacity: 0;
            &.visible{
                height: auto;
                opacity: 1;
            }

            a {
                display: inline-flex;
                align-items: center;
                strong{
                    display: flex;
                    align-items: center;
                    gap: 5px;
                    span{
                        display: flex;
                        align-items: center;
                        &:before{
                            font-size: 15px;
                        }
                    }
                }
            }

            .tip {
                z-index: 99;
                position: absolute;
                width: 200px;
                top: 100%;
                right: 0;
            }
        }

        .optionWrapper {
            margin-bottom: 30px;

            + .delivery_time {
                font-style: italic;
                margin-top: -30px;
                margin-bottom: 30px;
            }
        }

        .option {

            .form-group{
                label{
                    font-weight: 900!important;
                    color: $text;
                }
            }

            .options-wrap{
                display: flex;
                flex-wrap: wrap;
                gap: 10px;
                margin-bottom: 10px;
            }

            .radio-wrap{
                input[type="radio"]{
                    display: none;
                }
                display: inline-flex;
                align-items: center;
                justify-content: center;
                border-radius: 3px;
                border: 2px solid #F6F2F0;
                cursor: pointer;
                transition: all .3s;
                &.selected{
                    background-color: #FFE8DA;
                    border-color: $color2;
                }

                &.disabled{
                    cursor: not-allowed;
                    background-color: #F6F2F0;
                    border: 1px solid #F6F2F0;
                    label{
                        cursor: not-allowed;
                        color: #2C1B12;
                        opacity: .4;
                    }
                }

                label{
                    font-size: 16px!important;
                    font-weight: normal!important;
                    color: black;
                    cursor: pointer;
                    padding: 0 20px;
                }
            }

            .images {
                margin: 5px 0;

                .image {
                    display: inline-block;
                    border: 2px solid transparent;
                    margin-right: 5px;
                    cursor: pointer;

                    &.selected {
                        border-color: $color2;
                        border-radius: 3px;
                    }

                    img {
                        width: 50px;
                        height: 50px;
                        margin: 3px;
                    }
                }
            }
        }

        .form-group {
            position: relative;
            margin-bottom: 0px;
            @extend %clearfix;

            label {

                &.danger {
                    color: #ff0000;
                }
            }

            > div {
                //float: left;
                width: 100%;

                select,
                input {
                    border: 2px solid $color1;
                    padding: 10px 15px;
                    height: auto;
                    border-radius: 5px;

                    &::-webkit-inner-spin-button,
                    &::-webkit-outer-spin-button {
                        appearance: none;
                        margin: 0;
                    }
                }

                select {
                    appearance: none;
                    color: #000;
                    background: $color1 url('/img/select-grey.svg') no-repeat calc(100% - 15px) center;
                    background-size: auto 7px;
                }

                input {
                    width: 25%;
                    float: left;

                    &#quantity {
                        text-align: center;
                        border-radius: 100px;
                        width: 83px;
                        height: 60px;
                        font-size: 20px;
                    }
                }

                .btn_buy {
                    margin-top: 0;
                    margin-left: 5px;
                    background: $color2;
                    text-transform: initial;
                    text-align: center;
                    flex-grow: 1;

                    .icon {
                        display: inline-block;
                        float: none;
                        transform: translateY(2px);
                        line-height: normal;
                    }

                    &.disabled {
                        background: $gray-lighter;
                        color: $gray-dark;
                        cursor: not-allowed;

                        &:hover {
                            transform: none;
                            box-shadow: none;
                        }
                    }
                }
            }

            .sizeconfig {
                position: relative;
                //float: left;
                display: block;
                font-size: 14px;
                color: $color2;
                font-weight: bold;

                .icon {
                    position: relative;
                    top: 2px;
                }
            }
        }
    }

    .delivery_time_visible {
        display: none;
        margin-top: 20px;
        font-size: 16px;
        color: $text;
        position: relative;

        >span{
            display: block;
        }

        .stock-label {
            position: absolute;
            right: 0;
            color: white;
            background-color: #3D9558;
            padding: 2px 5px;
            display: block;
            border-radius: 10px 10px 10px 0;
            font-size: 14px;
            i{
                position: relative;
                top: 1px;
            }
        }
    }

    .row {
        position: relative;

        .value_option {
            margin: 15px 0;
        }

        .values {
            overflow: hidden;
            padding: 15px 0;

            > span {
                position: relative;
                display: block;
                // min-width: 14.28%;
                float: left;
                text-align: center;
                padding: 15px 0;
                border-right: 1px solid #D6DDE0;
                border-bottom: 1px solid #D6DDE0;
                background: $white;
                color: $black;
                text-decoration: none;
                transition: all 300ms ease;
                cursor: pointer;

                &:first-child,
                &:nth-child(8),
                &:nth-child(15) {
                    border-left: 1px solid #D6DDE0;
                }

                &:nth-child(-n+7) {
                    border-top: 1px solid #D6DDE0;
                }

                &.disabled {
                    color: #D1D9DD;
                    cursor: not-allowed;
                }

                .delivery_time {
                    position: absolute;
                    left: 50%;
                    top: -10px;
                    color: #fff;
                    text-transform: uppercase;
                    font-size: 12px;
                    padding: 2px 5px;
                    white-space: nowrap;
                    display: none;
                    z-index: 1;
                    background: $color1;

                    &.direct {
                        background: #4cb050;
                    }
                }

                .btn_buy {
                    text-transform: none;
                    text-align: center;
                    padding: 5px 15px;
                    margin-top: 30px;
                    font-size: 20px;
                    height: auto;
                    line-height: auto;
                    background: $color2;
                    color: #fff;
                }

                .address {
                    h3 {
                        color: $color1;
                        text-transform: uppercase;
                        font-size: 14px;
                    }

                    .verzending {
                        margin-top: 50px;
                        color: $color1;
                        font-size: 14px;

                        strong {
                            display: block;
                            font-size: 16px;
                        }
                    }
                }
            }

            &.kleur {
                > span {
                    padding: 0;
                    border: none;

                    img {
                        width: 40px;
                    }
                }
            }

            .points {
                margin-top: 30px;

                .inner {
                    padding: 15px 30px;
                    background: #F0F3F5;

                    ul {
                        margin: 0;
                        padding: 0;

                        li {
                            position: relative;
                            list-style: none;
                            padding: 7px 0 7px 25px;

                            &:before {
                                content: '\e903';
                                position: absolute;
                                font-family: 'icomoon';
                                color: $color1;
                                left: 0;
                                top: 7px;
                                font-size: 15px;
                            }
                        }
                    }
                }
            }
        }
    }

    .share {
        margin: 30px 0;

        p,
        .addthis {
            display: inline-block;
            font-size: 15px;
            color: #8E8E8E;
            line-height: 44px;
            vertical-align: top;
            margin: 0 10px 0 0;
        }
    }
}

.sqr-recommender{

    .sqr-recommender-slide{
        border: none!important;
        transition: all 300ms ease;
        &:hover{
            transform: scale(1.05);
        }

        .sqr-recommender-image-container{
            background-color: white!important;
            height:unset!important;
            img{
                width: 100%;
                height: auto;
                box-shadow: 0px 3px 6px #F6F2F0;
            }
        }
    }

    .sqr-recommender-title{
        font-size: 18px;
        margin: 15px 0 0 0;
        display: block;
        font-weight: bold;
        height: unset!important;
        color: #2C1B12 !important;
    }

    .sqr-recommender-text{
        display: none!important;
    }

    .sqr-recommender-prices{
        padding: 8px 12px 0 7px !important;
        .sqr-recommender-normal-price{
            display: block;
            margin-top: 10px;
            padding-bottom: 10px;
            font-size: 16px;
            font-weight: 100;
            color: $text!important;
        }
    }
}

#productinfo {
    background: $lightgray;
    padding: 30px 0;
    scroll-margin-top: 160px;

    .description,
    .reviews {
        background: #fff;
        border-radius: 10px;
        padding: 25px;
        scroll-margin-top: 160px;

        h3 {
            margin-top: 0;
            margin-bottom: 30px;
            font-weight:bold;
        }

        &.mb-xs {
            margin-bottom: 30px;
        }

        ul {
            padding: 0;

            li {
                position: relative;
                list-style: none;
                display: inline-flex;

                &:before {
                    content: '\e91b';
                    font-family: 'Icomoon', sans-serif;
                    color: $color2;
                    margin-right: 5px;
                    position: relative;
                    top: 1px !important;
                }
            }
        }
    }

    .description {
        #tabsNav {
            list-style: none;
            line-height: 1.1;

            li {
                padding: 0;
                margin-right: 30px;
                display: inline-block;
                font-size: 24px;
                cursor: pointer;
                font-weight: bold;
                color: #999;

                &:before {
                    content: none;
                }

                &:hover {
                    color: #333;
                }

                &.active {
                    color: #333;
                    cursor: default;
                }
            }
        }

        #tabs {
            .tab:not(.active) {
                display: none;
            }
        }
    }

    .reviews {
        .stars {
            position: relative;
            display: block;
            margin: 0 auto;

            &:before {
                content: attr(data-grade);
                position: absolute;
                top: calc(-100% - 11px);
                left: 50%;
                transform: translateX(-50%);
                background: rgba(0, 0, 0, 0.8);
                padding: 3px 5px;
                min-width: 30px;
                text-align: center;
                color: #fff;
                border-radius: 5px;
                font-size: 14px;
                visibility: hidden;
                opacity: 0;
                transition: all 300ms ease;
            }

            &:after {
                content: ' ';
                position: absolute;
                top: -5px;
                left: 50%;
                transform: translateX(-50%);
                width: 0;
                height: 0;
                border-left: 5px solid transparent;
                border-right: 5px solid transparent;
                border-top: 5px solid rgba(0, 0, 0, 0.8);
                visibility: hidden;
                opacity: 0;
                transition: all 300ms ease;
            }

            &:hover {
                &:before,
                &:after {
                    visibility: visible;
                    opacity: 1;
                }
            }

            .icon {
                display: inline-block;
                font-size: 9px;
                color: #e8e8e8;
            }

            .inner {
                position: absolute;
                left: 0;
                top: 0;
                white-space: nowrap;
                overflow: hidden;

                .icon {
                    color: $color2;
                }
            }
        }

        .review-modal{
            font-size: 16px;
            display: flex;
            align-items: center;
            gap: 10px;
            font-weight: bold;
        }

        h3 {
            position: relative;

            .stars {
                position: absolute;
                max-height: 22px;
                right: 0;
                bottom: 0;
                max-width: 50%;

                .icon {
                    vertical-align: top;
                }
            }
        }

        #allReviews {
            .reviews {
                padding-top: 50px;
            }

            .close {
                position: absolute;
                top: 12px;
                right: 15px;
                font-size: 20px;
                cursor: pointer;
            }
        }
    }
}

#productothers {
    background: #fff;
    padding: 60px 0;
}

#productCartModal {
    .modal-content {
        border-radius: 5px;

        .modal-header {
            padding: 30px;
            font-size: 18px;
            border-bottom: 2px solid #F6F2F0;

            .icon {
                margin-right: 15px;
                display: inline-block;
                border-radius: 50%;
                height: 20px;
                width: 20px;
                color: #fff;
                background: $color2;
                line-height: 20px;
                text-align: center;
                font-size: 14px;
            }
        }
    }

    .modal-body {
        position: relative;
        padding: 30px;
    }

    header {
        text-transform: uppercase;
        font-weight: bold;
        border-bottom: 1px solid #CCC;
        padding-bottom: 15px;
        padding-right: 25px;

        span {
            color: $color1;
            margin-right: 10px;
        }
    }

    .close {
        position: absolute;
        font-size: 28px;
        top: 10px;
        right: 0;
        cursor: pointer;
    }

    .image_wrapper {
        padding: 30px 0;

        img {
            width: 100%;
        }
    }

    .content_wrapper {
        padding: 30px 15px;

        h2 {
            font-size: 30px;
            font-weight: bold;
            margin: 0 0 10px 0;
        }

        .options {
            margin-bottom: 15px;

            .option {
                display: inline-block;
                position: relative;
                margin-right: 15px;

                &:after {
                    content: '/';
                    position: absolute;
                    right: -10px;
                }

                &:last-child {
                    &:after {
                        display: none;
                    }
                }
            }
        }

        .discount {
            margin-bottom: 0;

            strike {
                margin-left: 5px;
            }
        }

        .price {
            display: block;
            margin-top: 10px;
            font-size: 16px;
            font-weight: bold;
            color: $color2;

            .sale {
                font-size: 22px;
                color: $black;
                margin-bottom: 0;

                .old {
                    font-size: 22px;
                    text-decoration: line-through;
                    font-weight: normal;
                    margin-left: 10px;
                }
            }

            small {
                color: $black;
                font-size: 12px;
                margin-left: 5px;
            }
        }

        small {
            font-size: 10px;
            margin-left: 5px;
        }

        //.options {
        //  list-style: none;
        //  margin: 15px 0 0 0;
        //  border-top: 1px solid #d6dde0;
        //  padding: 15px 0 0 0;
        //}
    }

    .btn {
        text-transform: none;
        width: 100%;
        text-align: center;
        padding: 10px 0;
        font-size: 18px;
        margin-bottom: 15px;

        .grey {
            margin-bottom: 0;
        }
    }

    .modal-footer {
        border-top: 2px solid #F6F2F0;
        text-align: left;

        ul {
            padding-left: 0;

            .gerelateerdproduct {
                padding: 15px;
                border-radius: 5px;
                margin-right: 30px;
                width: 100%;
                text-align: center;

                h3 {
                    a {
                        color: #000;
                        font-size: 16px;
                    }
                }
            }
        }
    }
}

#videoModal,
#sizeConfiguratorModal {
    .close {
        position: absolute;
        top: 19px;
        right: 15px;
        cursor: pointer;
    }
}

#sizeConfiguratorModal {
    .control-label {
        i {
            margin-left: 5px;
            vertical-align: -3px;
            font-size: 16px;
        }
    }

    #overview {
        display: none;

        .calculated {
            margin-top: 15px;
            color: $color2;
            font-weight: bold;
        }
    }

}

@media (min-width: $screen-sm-min) {
    #product {
        #selectedVariantDeliveryTime{
            max-width: 100%;
        }
        .images-label{
            font-size: 18px !important;
        }
        #options{
            .form-group{
                label{
                    font-size: 18px!important;
                    margin-bottom: 0;
                    line-height: 34px;
                    color: $text;
                    font-weight: bold;
                }
            }
        }
        .gallery {
            margin: 0;

            .nav {
                .slick-list{
                    margin: 0 -10px;
                }
                .slick-slide {
                    border: 2px solid $color1;
                    border-radius: 5px;
                    overflow: hidden;
                    transition: all 300ms ease;
                    margin: 0 10px;

                    &.slick-current {
                        border-color: $color2;
                    }

                    &:last-child {
                        margin-bottom: 0;
                    }

                    img {
                        width: 100%;
                        height: auto;
                    }
                }

                .arrow {
                    position: relative;
                    cursor: pointer;
                    display: block;
                    text-align: center;
                    color: $color2;
                    font-size: 20px;
                    left: 50%;
                    transform: translateX(-50%);
                    z-index: 100;

                    &.prev-arrow {
                        top: 0;
                        margin-bottom: 15px;
                    }

                    &.next-arrow {
                        position: absolute;
                        bottom: 0;
                    }
                }
            }
        }

        .description-preview {
            font-size: 18px;
        }

        .discount,
        small {
            font-size: 16px;
        }

        .price {
            margin-top: 20px;
            font-size: 24px;
        }
    }

    #productinfo {
        padding: 50px 15px;

        .description,
        .reviews {
            &.mb-xs {
                margin-bottom: 0;
            }
        }
    }

    #productCartModal {
        .close {
            top: 25px;
            right: 10px;
        }

        .content_wrapper {
            .price {
                font-size: 22px;
            }
        }

        .modal-footer {
            ul {
                .gerelateerdproduct {
                    width: calc(50% - 15px);
                }
            }
        }
    }
}

@media (min-width: $screen-md-min) {
    #product {

        h1{
            font-size: 30px;
        }

        #options {
            .product-tip {
                .tip {
                    position: absolute;
                    top: 100%;
                }
            }
        }

        .points {
            .inner {
                ul {
                    column-count: 2;
                    column-gap: 15px;
                }
            }
        }

        .bottom {
            .share {
                float: left;
                text-align: inherit;
            }
        }

        .row {
            .values {
                > span {
                    &:hover:not(.disabled) {
                        background: #D6DDE0;

                        .delivery_time {
                            display: block;
                        }
                    }
                }
            }
        }
    }

    #productinfo {
        padding: 30px 15px;
    }

    #productothers{
        h2{
            font-size: 40px;
        }
    }

    #productCartModal {
        .modal-footer {
            ul {
                .gerelateerdproduct {
                    width: calc(33.33% - 20px);
                }
            }
        }
    }
}

@media (min-width: $screen-md-min) {
    #productinfo {
        #productOmschrijving{
            font-size: 16px;
        }
        .description {
            #tabsNav {
                margin-bottom: 20px;
                li{
                    font-size: 40px;
                }
            }
        }
        .reviews {
            .score{
                font-size: 20px;
                font-weight: bold;
            }
            .review-modal{
                font-size: 18px;
            }
            .stars{
                .icon{
                    font-size: 16px;
                }
            }
            h3{
                font-size: 40px;
            }
        }
    }
}



@media (min-width: 1440px) {
    #product {
        .row {
            .address {
                position: absolute;
                bottom: 0;
                right: 0;
                width: 33.33%;

                .col-sm-6 {
                    width: 100% !important;
                }
            }
        }

        #addToCartForm {
            .form-group {
                .sizeconfig {
                    position: absolute;
                    left: calc(100% + 15px);
                    top: 50%;
                    transform: translateY(-50%);
                    white-space: nowrap;
                }
            }
        }
    }

    #productinfo {
        margin-top: 50px;
    }
}