#reviews {
    .overall {
        strong {
            font-size: 30px;
            color: $color1;
        }
    }

    .summary_wrapper {
        background: $color1;
        margin: 30px 0;
        padding: 30px 30px 20px 30px;
        border-radius: 15px;
        p {
            color: #FFF;
        }
    }

    .review {
        padding: 30px;
        margin: 0 0 30px 0;
        border-radius: 15px;
        background: #F5F5F5;
    }

    .client {
        margin: 0 0 15px 0;
        padding: 0;
        font-size: 20px;
        font-weight: bold;
        color: $color1;
    }

    .header {
        line-height: 24px;
        overflow: hidden
    }

    .score {
        float: left;
        margin: 0;
        padding: 0;
        font-size: 24px;
        font-weight: 900;
    }

    .date {
        float: right;
        margin: 0;
        padding: 0;
        font-style: italic;
    }

    .body {
        margin-top: 15px;
        padding-top: 15px;
        border-top: 1px solid #FFF;
        p {
            margin: 0 0 15px 0;
            padding: 0;

            &:last-of-type {
                margin-bottom: 0px;
            }
        }
    }

    .question {

    }

    .shop {
        position: relative;
        margin-top: 30px;
        padding-left: 45px;

        &:before {
            position: absolute;
            left: 20px;
            top: -5px;
            content: '';
            width: 3px;
            height: 100%;
            background: #CCC;
        }
    }

    .shop_comment_date {
        font-style: italic;
        float: right;
        font-weight: normal;
        font-size: 16px;
    }

    .shop_comment {
        margin: 0;
    }

}