[data-tooltip]:hover {
  position: relative;
}

[data-tooltip]:hover:after {
  background-color: #000000;
  background-color: rgba(0, 0, 0, 0.8);

  -webkit-box-shadow: 0px 0px 3px 1px rgba(50, 50, 50, 0.4);
  -moz-box-shadow: 0px 0px 3px 1px rgba(50, 50, 50, 0.4);
  box-shadow: 0px 0px 3px 1px rgba(50, 50, 50, 0.4);

  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 5px;

  color: #FFFFFF;
  font-size: 12px;
  content: attr(data-tooltip);

  margin-bottom: 10px;
  top: 130%;
  left: 0;
  padding: 7px 12px;
  position: absolute;
  width: auto;
  min-width: 200px;
  max-width: 300px;
  word-wrap: break-word;

  z-index: 9999;
}