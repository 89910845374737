#products {

    .read-more {
        display: flex;
        align-items: center;
        font-weight: bold;
        gap: 5px;

        div {
            width: 12px;
            height: 12px;
            background: $color2;
            display: flex;
            align-items: center;
            justify-content: center;
            color: #FFF;
            border-radius: 50%;
            font-size: 9px;
        }
    }

    #filter-toggle {
        margin-top: 15px;
        margin-bottom: 15px;

        .btn {
            cursor: pointer;
        }
    }

    #blogitem {
        margin: 15px 0 30px;

        h3 {
            color: $text;
            margin: 0;
            padding: 15px 0;
            font-weight: bold;
        }

        a {
            display: block;
            color: #000;

            img {
                display: block;
                width: 100%;
                border-radius: 10px 10px 0 10px;
            }

            strong {
                position: relative;
                display: block;
                padding: 15px 0;

                span {
                    float: right;
                }
            }
        }
    }

    .page-content {

        .text {
            overflow: hidden;

            a {
                transition: all .3s;
            }
        }
    }

    .products {
        .count {
            float: left;
            font-size: 15px;
            line-height: 44px;
        }

        .order_wrapper {
            float: right;
            text-align: right;
            width: 70%;

            .form-group {
                position: relative;
                margin-bottom: 0;
                @extend %clearfix;

                label {
                    display: none;
                    // float: right;
                    line-height: 44px;
                    white-space: nowrap;
                    margin-right: 15px;
                    font-weight: normal;
                }

                > div {
                    float: right;
                    width: 100%;
                    max-width: 170px;

                    select {
                        border: 2px solid $color1;
                        padding: 10px 30px 10px 15px;
                        height: auto;
                        border-radius: 5px;
                    }

                    select {
                        appearance: none;
                        color: #000;
                        background: $color1 url('/img/select-grey.svg') no-repeat calc(100% - 15px) center;
                        background-size: auto 7px;
                        box-shadow: none;
                    }
                }
            }
        }

        .list {
            background: #F6F2F0;
            margin: 10px -15px 0;
            padding: 0 10px;
        }
    }
}

@media (min-width: $screen-md-min) {
    #products {
        .page-content {
            h1 {
                font-size: 40px;
            }
        }

        .products {

            .list {
                background: none;
                margin-top: 0;
                padding: 0;
            }
        }
    }
}