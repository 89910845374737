#olfitclub {
    h1 {
        margin: 0;
        text-align: center;
    }

    .intro {
        font-size: 20px;
        margin: 15px 0;
    }

    p {
        margin: 15px 0;
        line-height: 1.7;
        font-size: 16px;
        text-align: center;
    }

    .wrapper {
        padding: 60px 0;
        margin-bottom: 30px;
        text-align: center;

        &.wrapper-gray {
            background: $lightgray;
        }

        p {
            font-size: 16px;
        }

        [class^=icon-] {
            font-size: 90px;
            color: $color2;
        }

        .btn.ghost {
            margin-left: 15px;
        }
    }
}