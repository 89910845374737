.reviews {
    .review {
        border: 1px solid #F6F2F0;
        border-radius: 10px;
        margin-bottom: 15px;

        .text{
            p{
                margin-bottom: 10px;
            }
            strong{
                color: $text;
            }
        }
        
        &:last-child {
            margin-bottom: 0;
        }

        .title {
            background: #F6F2F0;
            padding: 10px 15px;
            font-weight: bold;
            text-align: center;

            span {
                max-width: 80%;
            }
        }

        .content {
            padding: 10px 15px;

            .pros {
                li {
                    color: #8E8E8E;
                    font-size: 15px;
                    display: flex;
                    align-items: baseline;

                    &:before {
                        content: '\e901' !important;
                        color: #3C9558 !important;
                        font-size: 14px;
                        line-height: 1.1;
                    }
                }
            }
            .cons {
                li {
                    color: #8E8E8E;
                    align-items: baseline;

                    &:before {
                        content: '\e900' !important;
                        color: #A53D00 !important;
                        font-size: 14px;
                        line-height: 1.1;
                    }
                }
            }

            .reply {
                padding-top: 20px;

                h3 {
                    font-size: 16px!important;
                    margin-bottom: 10px!important;
                    color: #FF6308;
                }

                p {
                    font-style: italic;
                    color: #444;
                }
            }
        }
    }
}

@media (min-width: $screen-md-min) {
    .reviews {
        .review {
            .text{
                strong{
                    font-size: 16px;
                }
            }
            .title {
                text-align: left;
                font-size: 18px;
                
                .stars {
                    float: right;
                }
            }

            .pros,
            .cons {
                li {
                    &:before {
                        top: -1px !important;
                    }
                }
            }
        }
    }
}