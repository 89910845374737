#brands {
    padding: 30px 0;
    background: white;

    .brand-slider {
        padding: 0 25px;

        .slick-slide{
            height: auto;
        }

        .brand {
            display: inline-block;
            margin: 0 15px;

            img {
                height: 25px;
                filter: grayscale(100%);
                width: auto;
            }
        }

        .slick-arrow {
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            color: rgba(#8E8E8E, 0.5);
            z-index: 100;
            font-size: 20px;

            &.prev {
                left: 0;
            }
            &.next {
                right: 0;
            }
        }
    }
}

@media (min-width: $screen-sm-min) {
    #brands {
        padding: 50px 0;

        .brand-slider {
            .brand {
                margin: 0 30px;
                
                img {
                    height: 40px;
                }
            }
        }
    }
}

@media (min-width: $screen-md-min) {
    #brands {
        .brand-slider {
            padding: 0 30px;

            .slick-arrow {
                font-size: 30px;
            }
        }
    }
}

@media (min-width: 1540px) {
    #brands {
        .brand-slider {
            padding: 0;

            .slick-arrow {
                font-size: 40px;

                &.prev {
                    left: -40px;
                }
                &.next {
                    right: -40px;
                }
            }
        }
    }
}