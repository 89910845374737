.products {
    margin-bottom: 30px;

    .product {
        margin-top: 15px;
        padding: 0 5px 0 5px;

        .inner {
            position: relative;
            background: #FFF;
            border-radius: 10px;
            overflow: hidden;
            transition: all 300ms ease;
            padding: 10px;

            .img-wrap {
                position: relative;
                transition: all 300ms ease;
                margin: -10px -10px 0;

                .label {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    white-space: nowrap;
                    position: absolute;
                    top: 10px;
                    right: 10px;

                    span {
                        &.laatste-exemplaren {
                            font-size: 12px;
                        }
                    }

                    &.type-label {
                        font-size: 15px;
                        color: $color2;
                        padding: 10px 0;
                    }

                    &.type-ballon {
                        font-size: 12px;
                        color: #fff;
                        text-align: center;
                        z-index: 1;
                        border-radius: 10px 10px 10px 0;
                        font-weight: 100;
                    }

                    &.orange {
                        background: $color2;
                    }

                    &.black {
                        background: #000000;
                    }
                }
            }

            .col-xs-3 {
                padding: 0 10px !important;
            }


            img {
                width: 100%;
                height: auto;
            }

            span.title {
                display: block;
                font-size: 12px;
                line-height: 15px;
                font-weight: bold;
                color: #000;
            }

            .price {
                display: flex;
                gap: 10px;
                margin: 5px 0;
                font-size: 12px;
                color: $black;
                font-weight: bold;
                opacity: 50%;

                .sale {
                    margin-bottom: 0;
                    font-weight: normal;

                    .old {
                        text-decoration: line-through;
                        font-weight: normal;
                    }
                }

                small {
                    display: none;
                    font-size: 12px;
                    margin-left: 5px;
                }
            }

            .usps {
                margin: 0;
                padding: 0;
                font-size: 10px;
                color: #3E3E3E;

                li {
                    position: relative;
                    list-style: none;
                    padding-left: 13px;

                    &:before {
                        content: '\e902';
                        font-family: 'icomoon', sans-serif;
                        color: $color2;
                        position: absolute;
                        top: 1px;
                        left: 0;
                    }
                }
            }
        }

        .variants {
            position: absolute;
            color: $color2;
            right: 0;
            bottom: 0;
            padding: 2px 7px;
            border-radius: 5px;
            font-size: 10px;
            font-weight: bold;
        }

        &.has-label {
            .inner {
                padding-top: 0;
            }
        }
    }
}

@media (min-width: $screen-sm-min) {
    .products {
        .product {
            margin-top: 30px;
            padding: 0 15px 0 15px;

            .inner {
                padding-bottom: 25px;

                .img-wrap {
                    .label {
                        right: auto;
                        left: 10px;
                    }
                }

                .col-xs-3 {
                    padding: 0 15px !important;
                }

                span.title {
                    font-size: 18px;
                    line-height: 27px;
                    margin: 15px 0;
                    display: block;
                }

                .price {
                    font-size: 16px;
                    margin: 10px 0;
                }

                .usps {
                    font-size: 14px;

                    li {
                        padding-left: 20px;
                    }
                }

                .btn {
                    position: absolute;
                    right: 15px;
                    bottom: 15px;
                    opacity: 0;
                    width: 40px;
                    height: 40px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    border-radius: 100%;
                    padding: 0;

                    i {
                        float: unset;
                        margin: unset;
                    }
                }

                &:hover {
                    .btn {
                        opacity: 1;
                    }
                }
            }

            .variants {
                top: 15px;
                right: 30px;
                width: 40px;
                bottom: inherit;
                left: inherit;
                background: none;

                .variant {
                    width: 39px;
                    height: 39px;
                    border: 1px solid transparent;
                    transition: all 300ms ease;
                    border-radius: 2px;

                    img {
                        width: 30px;
                        height: auto;
                        margin: 5px auto;
                    }

                    &.slick-current {
                        border-color: $color2;
                    }
                }

                .slick-arrow {
                    display: block;
                    text-align: center;
                    transition: all 300ms ease;

                    &.slick-disabled {
                        opacity: 0;
                        visibility: hidden;
                    }

                    &.prev {
                        margin-bottom: 5px;
                    }

                    &.next {
                        margin-top: 5px;
                    }
                }
            }
        }
    }
}

@media (min-width: $screen-md-min) {
    .products {
        .product {
            .inner {
                border-radius: 0;
                overflow: visible;

                .img-wrap {
                    border: 1px solid #f6f2f0;
                    border-radius: 10px;
                    overflow: hidden;
                    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.05);
                }
            }

            &:hover {
                .inner {
                    transform: scale(1.01);

                    .img-wrap {
                        box-shadow: 0 3px 6px rgba(0, 0, 0, 0.1);
                    }
                }
            }
        }
    }
}