@font-face {
  font-family: 'icomoon';
  src:  url('fonts/icomoon.eot?suo84q');
  src:  url('fonts/icomoon.eot?suo84q#iefix') format('embedded-opentype'),
  url('fonts/icomoon.ttf?suo84q') format('truetype'),
  url('fonts/icomoon.woff?suo84q') format('woff'),
  url('fonts/icomoon.svg?suo84q#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-tag:before {
  content: "\e924";
}
.icon-online-shopping:before {
  content: "\e925";
}
.icon-delivery-truck:before {
  content: "\e926";
}
.icon-information-logotype-in-a-circle:before {
  content: "\e923";
}
.icon-minus:before {
  content: "\e921";
}
.icon-lightbulb-regular:before {
  content: "\e922";
}
.icon-checkmark:before {
  content: "\e902";
}
.icon-tick:before {
  content: "\e902";
}
.icon-shopping-basket:before {
  content: "\e90e";
}
.icon-topsite:before {
  content: "\e90f";
}
.icon-truck-ramp-box:before {
  content: "\e910";
}
.icon-user-check:before {
  content: "\e911";
}
.icon-user-x:before {
  content: "\e912";
}
.icon-x:before {
  content: "\e913";
}
.icon-menu:before {
  content: "\e914";
}
.icon-plus:before {
  content: "\e915";
}
.icon-user-plus:before {
  content: "\e916";
}
.icon-eye-off:before {
  content: "\e917";
}
.icon-eye:before {
  content: "\e918";
}
.icon-instagram:before {
  content: "\e919";
}
.icon-phone:before {
  content: "\e91a";
}
.icon-check:before {
  content: "\e91b";
}
.icon-arrow-right:before {
  content: "\e91c";
}
.icon-arrow-left:before {
  content: "\e91d";
}
.icon-arrow-down:before {
  content: "\e91e";
}
.icon-arrow-up:before {
  content: "\e91f";
}
.icon-search:before {
  content: "\e920";
}
.icon-minus-solid:before {
  content: "\e900";
}
.icon-add-solid:before {
  content: "\e901";
}
.icon-youtube-play:before {
  content: "\e903";
}
.icon-linkedin-square:before {
  content: "\e904";
}
.icon-facebook-square:before {
  content: "\e905";
}
.icon-paper-plane-o:before {
  content: "\e906";
}
.icon-send-o:before {
  content: "\e906";
}
.icon-comments-o:before {
  content: "\e907";
}
.icon-star-half:before {
  content: "\e908";
}
.icon-star:before {
  content: "\e909";
}
.icon-caret-right:before {
  content: "\e90a";
}
.icon-caret-left:before {
  content: "\e90b";
}
.icon-caret-up:before {
  content: "\e90c";
}
.icon-caret-down:before {
  content: "\e90d";
}
